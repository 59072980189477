import { useContext, useState } from "react";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import Context from "../../../../Context";
import { serverLine } from "../../../../controllers/network/serverLine";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../helperComponents/CustomButton";

export default function AskWeightPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [weight, setWeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <OnboardingBoilerplate
      title={t("onboardingWeightTitle")}
      desc={t("onboardingWeightDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <MaterialInput
        type="number"
        label={t("onboardingWeightInput")}
        // placeholder={"Please! Type your weight in kg"}
        value={weight}
        onChange={extractEventValue(setWeight)}
      />

      <CustomButton
        style={{ marginBottom: "-10px", marginTop: "10px" }}
        onClick={skip}
        variant="minimal-underlined"
      >
        {t("onboardingSkip")}
      </CustomButton>
    </OnboardingBoilerplate>
  );

  async function skip() {
    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { skipWeight: true } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, skipWeight: true };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert("Saved");
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  async function onSubmit() {
    if (!weight) return window.popupAlert(t("onboardingWeightWarning"));

    let theWeight = parseInt(weight);

    if (isNaN(theWeight)) {
      return window.popupAlert("Invalid weight");
    }

    setLoading(true);

    try {
      await serverLine.patch("/me", { changes: { weight: theWeight } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, weight: theWeight };
      setLoggedInUser(newLoggedInUser);
      window.popupAlert("Saved");
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
