import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  padding: 10px 15px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  align-items: center;
`;
const Page = styled.div`
  display: flex;
  height: 100%;
  padding: 0 15px;

  justify-content: center;
  align-items: center;
`;

export default function UserPagination({
  currentData,
  pagination,
  setPagination,
}) {
  return (
    <Container>
      <Button onClick={decrement}>
        <AiOutlineLeft />
      </Button>
      <Page>{pagination.page + 1}</Page>
      <Button onClick={increment}>
        <AiOutlineRight />
      </Button>
    </Container>
  );

  function increment() {
    let newPagination = { ...pagination };
    newPagination.page++;
    setPagination(newPagination);
  }

  function decrement() {
    let newPagination = { ...pagination };
    newPagination.page--;
    if (newPagination.page < 0) return;
    setPagination(newPagination);
  }
}
