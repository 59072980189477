import styled from "styled-components";
import WhyChooseUs from "./WhyChooseUs.js";
import CompanyStat from "./CompanyStat.js";
import Testimonials from "./Testimonials.js";

import Footer from "./Footer.js";
import AboutUs from "./AboutUs.js";
import FeatureRow from "./FeatureRow.js";

import LoggedOutMembershipSection from "./LoggedOutMembershipSection.js";
import LoggedOutHeader from "./LoggedOutHeader.js";
import PrimaryButton from "../../../helperComponents/PrimaryButton.js";

import { useContext, useEffect, useRef, useState } from "react";
import ContactUs from "./ContactUs.js";
import Context from "../../../../Context.js";
import goTo from "../../../../controllers/goTo.js";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import LoggedOutHomeForApp from "./LoggedOutHomeForApp.js";
import StylishUnderline from "../../../helperComponents/StylishUnderline.js";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;

  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Tagline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* align-items: center; */

  animation: centerScaleReveal 0.4s ease-in;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    /* flex-direction: row; */
    width: 100%;
  }
`;

const Taglines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* align-items: center; */

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    gap: 0;
    flex-direction: column;
    gap: 20px;
    /* flex-direction: row; */
    width: 100%;
  }
`;

const TaglineLine = styled.div`
  color: var(--accentColor);
  font-size: 65px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;

  @media (max-width: 900px) {
    font-size: 30px;
    text-align: center;
    width: auto;
  }
`;

const TaglineLine2 = styled(TaglineLine)`
  font-size: 65px;

  @media (max-width: 900px) {
    font-size: 30px;
  }
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const Medium = styled.div`
  font-size: 18px;
  font-weight: 600;

  line-height: 30px;
  font-family: "Questrial", serif;
  width: 400px;

  animation: centerScaleReveal 0.4s ease-in;
  opacity: 0.5 !important;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 30px;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  margin-top: 10px;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85vw;
  height: 60vh;
  gap: 100px;

  @media (max-width: 900px) {
    height: auto;
    gap: 20px;
    width: 95vw;
    flex-direction: column-reverse;
  }
`;

const AnimatedHeroImage = styled.img`
  width: 1000px;
  margin-right: -200px;
  margin-top: 50px;
  border-radius: 15px;
  object-fit: cover;

  animation: centerScaleReveal 0.4s ease-in;

  @media (max-width: 900px) {
    width: 90vw;
    height: auto;
    border-radius: 10px;
    height: 40vh;
    margin: 0;
    margin-top: 20px;
  }
`;

const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  width: 100vw;

  @media (max-width: 900px) {
    padding: 0;
    align-items: center;
  }
`;

const Underline = styled.div`
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  opacity: 1;
  background: linear-gradient(
    90deg,
    transparent,
    4%,
    var(--accentColor),
    transparent
  );
`;

export default function LoggedOutHomePage() {
  const { isMobile } = useContext(Context);

  const [refreshRef, setRefreshRef] = useState(null);

  const { t } = useTranslation();

  //without this line we will not be able to refresh once the page is changed
  //the above line prevents a buh in which when we go to the login page and backend to the homepage
  //the ref disappears

  useEffect(() => {
    setRefreshRef(true);
  }, []);

  const containerRef = useRef();

  console.log("containerRef.current", containerRef.current);

  let platform = Capacitor.getPlatform();

  // platform = "ios";
  if (platform !== "web") return <LoggedOutHomeForApp />;

  return (
    <ParentContainer id="scrollableParent" ref={containerRef}>
      <FirstSection>
        <LoggedOutHeader />

        <HeroSection>
          <TextContainer>
            <Tagline>
              <Taglines>
                <TaglineLine>{t("loggedOutHeroTitleLine1")}</TaglineLine>
                <TaglineLine2>{t("loggedOutHeroTitleLine2")}</TaglineLine2>
              </Taglines>

              {isMobile ? (
                <StylishUnderline style={{ width: "100%" }} />
              ) : (
                <Underline />
              )}
            </Tagline>

            <Medium> {t("loggedOutHeroDesc")} </Medium>

            <br />
            <MainButtons>
              <PrimaryButton
                // icon={null}
                onClick={goTo("/signup")}
                style={{
                  width: "300px",

                  padding: isMobile ? "10px 0" : "13px 0",
                  borderRadius: isMobile ? "10px" : "100px",
                }}
                textStyle={{ fontSize: "15px" }}
              >
                {t("becomeAMember")}
              </PrimaryButton>

              {isMobile ? (
                <PrimaryButton
                  onClick={goTo("/auth")}
                  style={{
                    width: "300px",
                    borderRadius: "10px",
                    padding: "10px 0",
                  }}
                  textStyle={{ fontSize: "15px" }}
                >
                  {t("alreadyAMember")}
                </PrimaryButton>
              ) : null}
            </MainButtons>
          </TextContainer>

          {isMobile ? (
            <AnimatedHeroImage src="/hero/reception.jpg" />
          ) : (
            <AnimatedHeroImage src="/hero/heroimage.png" />
          )}
        </HeroSection>
      </FirstSection>
      <Main>
        <FeatureRow />

        {containerRef.current ? (
          <>
            <WhyChooseUs containerRef={containerRef} />
            <CompanyStat containerRef={containerRef} />
            <Testimonials containerRef={containerRef} />
            <LoggedOutMembershipSection containerRef={containerRef} />
            <AboutUs containerRef={containerRef} />
            <ContactUs containerRef={containerRef} />
            <Footer containerRef={containerRef} />
          </>
        ) : null}
      </Main>
    </ParentContainer>
  );
}
