import styled from "styled-components";
import PrimaryButton from "./PrimaryButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px;
  width: 430px;
  height: auto;
  border-radius: 10px;
  /* box-shadow: 1px 1px 20px 20px #d1da3012; */
  border: 1px solid #222;
  background: rgb(6 6 6);
  padding-bottom: 50px;

  /* background: #111 !important; */

  ${({ desktopWidth }) => {
    if (desktopWidth) return `width:${desktopWidth};`;
  }}

  /* animation: centerScaleReveal 0.4s ease-in; */

  @media (max-width: 900px) {
    width: 90vw !important;
    padding: 10px;
    padding-bottom: 30px;
    background-color: transparent;
    box-shadow: none;
    /* height: 75vh; */
    border: none;
    justify-content: center;
  }
`;

const Title = styled.div`
  font-weight: 900;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  color: var(--accentColor);
  text-transform: uppercase;
  margin-top: 20px;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
`;

const Medium = styled.div`
  /* font-weight: 900; */
  opacity: 0.4;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: var(--accentColor);
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

const Underline = styled.div`
  height: 2px;
  border-radius: 5px;
  margin-top: -5px;
  width: 62%;
  opacity: 0.8;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accentColor),
    transparent
  );
`;

export default function InputCard({
  title,
  desc,
  children,
  onSubmit,
  submitButtonText,
  style,
  textContainerStyle,
  additionalButtons,
  desktopWidth,
}) {
  return (
    <Container desktopWidth={desktopWidth} style={style}>
      {title ? (
        <Top style={textContainerStyle}>
          <Title>{title}</Title>
          {title ? <Underline /> : null}
          {desc ? <Medium>{desc}</Medium> : null}
        </Top>
      ) : null}

      <Inputs>{children}</Inputs>
      <Buttons>
        {additionalButtons}
        {onSubmit ? (
          <PrimaryButton
            style={{
              borderRadius: "50px",
              boxShadow:
                "rgb(96, 124, 28) 1px 5px 0px 0px, rgb(96 124 28 / 61%) 1px 5px 50px 0px",
            }}
            onClick={onSubmit}
          >
            {submitButtonText}
          </PrimaryButton>
        ) : null}
      </Buttons>
    </Container>
  );
}
