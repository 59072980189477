import styled from "styled-components";
import WhyChooseUsCard from "./WhyChooseUsCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import CompanyStatCard from "./CompanyStatCard";

import { useSpring, animated } from "react-spring";
import LoggedOutSectionTitle from "./LoggedOutSectionTitle";
import { useTranslation } from "react-i18next";
import StylishUnderline from "../../../helperComponents/StylishUnderline";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const Underline = styled.div`
  height: 5px;
  border-radius: 5px;
  width: 62%;
  background-color: var(--accentColor);
`;

export default function CompanyStat({ containerRef }) {
  const targetRef = useRef(null);

  const { t } = useTranslation();

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  const props = useSpring({
    from: { number: 0 },
    to: { number: 100000 },
    config: { duration: 5000 }, // Duration in milliseconds
  });

  console.log(
    "vvvvvv",
    props.number.to((n) => n.toFixed(0))
  );

  return (
    <Container ref={targetRef} id="our-track-record">
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <LoggedOutSectionTitle>
            {t("ourTrackRecordSectionTitle")}
          </LoggedOutSectionTitle>
          <StylishUnderline />

          {/* <Description>
            We have earned the trust of thousands of students because we give
            incredible care in what is taught and how it is taught.
          </Description> */}
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          <CompanyStatCard
            gradient={"linear-gradient(#000000, #868686)"}
            image="four"
            title={t("ourTrackRecord1")}
            description={t("ourTrackRecord1Desc")}
          />
          <CompanyStatCard
            gradient={"linear-gradient(#000000, #868686)"}
            image="happy"
            title={t("ourTrackRecord2")}
            description={t("ourTrackRecord2Desc")}
          />
          <CompanyStatCard
            gradient={"linear-gradient(#000000, #868686)"}
            image="trainer"
            title={t("ourTrackRecord3")}
            description={t("ourTrackRecord3Desc")}
          />
        </List>
      </motion.div>
    </Container>
  );
}
