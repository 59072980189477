import styled from "styled-components";

import LogoSVG from "./LogoSVG.js";
import Link from "../helperComponents/Link.js";
import goTo from "../../controllers/goTo.js";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;

  /* animation: centerScaleReveal 0.4s ease-in; */
`;

const LogoImg = styled.img`
  height: auto;
  width: 200px;
  height: 77px;

  @media (max-width: 900px) {
    width: 120px;
    height: 47px;
  }
`;

export default function BrandContainer() {
  window.goTo = goTo;
  return (
    <Link href="/">
      <BrandContainerMain>
        <LogoImg src={"/full-logo.jpg"} />
        {/* <H1>Wellness</H1> */}
      </BrandContainerMain>
    </Link>
  );
}
