import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MembershipSelectionCard from "../onboarding/MembershipSelectionCard";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import LoggedInMembershipPlanCard from "./LoggedInMembershipPlanCard";
import PrimaryButton from "../../../helperComponents/PrimaryButton";

import PullToRefresh from "react-simple-pull-to-refresh";
import fetchDataWithCaching from "../../../../controllers/network/fetchDataWithCaching";
import Context from "../../../../Context";
import { MdDone } from "react-icons/md";
import { t } from "i18next";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0;
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
`;

const PlanFeatures = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 200px;
`;

const ExistingRequest = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  padding: 40px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;
`;

const ExistingRequestText = styled.div`
  font-size: 12px;
  font-weight: 800;
  color: #c9d400;
  margin: 0;
  font-style: italic;
  text-align: center;

  text-transform: capitalize;

  font-family: "Montserrat", sans-serif;
`;

const LoggedInMembershipPlans = () => {
  const { membershipPlans, updateMembershipPlans } = useContext(Context);
  const [existingRequest, setExistingRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadStuff(false);
  }, []);

  async function loadStuff(skipCache) {
    let data = await fetchDataWithCaching({
      path: "/membership-request",
      // setLoading,
      //set loading is disabled,
      // because in membership-request is null most of the time
      //null value is not cached
      //so it will make the loading things happen every time
      onFreshData: setExistingRequest,
      skipCache,
    });
    setExistingRequest(data);
    // await updateMembershipPlans();
    setLoading(false);
  }

  async function handleRefresh() {
    await loadStuff(true);
  }

  // function handleRefresh() {
  //   return new Promise((resolve) => {});
  // }

  if (existingRequest) {
    // if (loading) return <LoadingSection />;
    return (
      <ExistingRequest>
        <ExistingRequestText>
          {t("membershipRequestSentTitle")}:{" "}
          {existingRequest.membershipPlan.name}
        </ExistingRequestText>

        <PrimaryButton style={{ width: "150px" }} onClick={cancelRequest}>
          Cancel
        </PrimaryButton>
      </ExistingRequest>
    );
  }

  let content = (
    <Container>
      {membershipPlans.map((item, index) => {
        if (item.status == "DISABLED") return null;
        return (
          <LoggedInMembershipPlanCard
            membershipPlanID={item._id}
            loadStuff={loadStuff}
            key={index}
            title={item.name}
            setLoading={setLoading}
            desc={item.description}
            price={item.price + " CFA / Month"}
            name={item.name.toUpperCase()}
            features={
              <PlanFeatures>
                <PlanFeature>{item.feature1}</PlanFeature>
                <PlanFeature>{item.feature2}</PlanFeature>
                <PlanFeature>{item.feature3}</PlanFeature>
                <PlanFeature>{item.feature4}</PlanFeature>
              </PlanFeatures>
            }
          />
        );
      })}
    </Container>
  );

  // return content;

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      {loading ? <LoadingSection /> : null}
      {content}
    </PullToRefresh>
  );

  async function cancelRequest() {
    setLoading(true);

    try {
      await serverLine.post("/cancel-membership-request");
    } catch (e) {
      window.popupAlert(e.message);
    }

    await loadStuff();
    setLoading(false);
  }
};

const PlanFeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
`;

const Tick = styled.div`
  background-color: #e3f004;
  height: 20px;
  width: 20px;
  color: #000;
  border-radius: 100px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  /* margin-top: -3px; */
`;

const PlanFeatureText = styled.div``;

function PlanFeature({ children }) {
  return (
    <PlanFeatureContainer>
      <Tick>
        <MdDone />
      </Tick>
      <PlanFeatureText>{children}</PlanFeatureText>
    </PlanFeatureContainer>
  );
}

export default LoggedInMembershipPlans;
