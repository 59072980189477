import { useTranslation } from "react-i18next";
import ListPage from "../../ListPage";
import ActivityBookingCard from "../ActivityBookingCard";
import styled from "styled-components";
import { useContext, useState } from "react";
import DropDownEmployee from "../upcomingActivities/DropDownEmployee";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function MyActivitiesPage() {
  const { isMobile } = useContext(Context);
  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  return (
    <ListPage
      colsOnMobile={2}
      title={t("myActivitiesTitle")}
      path="/activity-bookings"
      CardComponent={ActivityBookingCard}
      filterFunction={filterByCoach}
      filterComponent={
        <Row>
          <DropDownEmployee value={value} onChange={setValue} />

          <CustomButton
            textStyle={{ fontSize: isMobile ? "9px" : "11px" }}
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setValue(null);
            }}
          >
            Reset
          </CustomButton>
        </Row>
      }
    />
  );

  function filterByCoach(dataList) {
    if (!value) return dataList;

    return dataList.filter((item) => item.activity.employeeID == value);
  }
}
