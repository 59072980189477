import React from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import formatDate from "../../../../controllers/formatDate";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 350px;
  overflow: hidden;
  color: white;
  position: relative;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #303030;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #c9d400;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #c9d400;
  margin: 0;

  text-transform: capitalize;
  font-size: 18px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #808080;
  margin-top: 10px;
`;

export default function OrderCard({ item }) {
  const { t } = useTranslation();

  let images = item.item.images ? item.item.images : [];

  return (
    <SemanticButton href={`/store-item/?itemID=${item.itemID}`}>
      <Card>
        <ImageContainer>
          <Image src={getImageURL(images[0])} alt="" />
        </ImageContainer>
        <InfoContainer>
          <Title>{item.item.title}</Title>
          <Subtitle>
            {t("myOrdersPrice")}: {item.item.price}
          </Subtitle>
          <Subtitle>
            {t("myOrdersQuantity")}: {item.quantity}
          </Subtitle>
          <Subtitle>
            {t("myOrdersStatus")}: {t(item.status)}
          </Subtitle>
          <Subtitle>
            {t("myOrdersAddress")}: {item.address}
          </Subtitle>
          <Subtitle>
            {t("myOrdersDeliveryDate")}:{" "}
            {item.deliveryDate ? item.deliveryDate : "Pending"}
          </Subtitle>
        </InfoContainer>
      </Card>
    </SemanticButton>
  );
}
