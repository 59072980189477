import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import LoadingSection from "../../../helperComponents/LoadingSection";
import Section from "../../../helperComponents/Section";
import styled from "styled-components";
import CustomButton from "../../../helperComponents/CustomButton";
import ActivityCard from "../activity/ActivityCard";
import AnnouncementSlideShowCard from "./AnnouncementSlideShowCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AliceCarousel, { Link } from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarouselLink from "../../../helperComponents/AliceCarouselLink";
import { useTranslation } from "react-i18next";
import { Image, Shimmer } from "react-shimmer";
import Context from "../../../../Context";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-size: 27px;
  color: var(--accentColor);
`;

const TopRight = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 650px;
  height: 330px;
  /* background-color: #111; */
  padding: 30px 0;
  padding-bottom: 10px;
  border-radius: 16px;
  animation: fadeIn 0.6s ease-in;
  /* background: linear-gradient(
    45deg,
    #d4cfcf1c,
    rgba(255, 255, 255, 0.05),
    #c8c8c824
  ); */
  /* border: 1px solid #d1da303b; */
  padding: 0;
  @media (max-width: 900px) {
    padding: 10px 0;
    width: 90vw;
    height: 260px;
  }
`;

export default function AnnouncementSection({ data }) {
  const { isMobile } = useContext(Context);
  let items = data;

  if (!items)
    return (
      <Container>
        <Shimmer width="100%" height={isMobile ? "280px" : "400px"} />
      </Container>
    );

  let allItems = items.slice(0, 7).map((item, index) => (
    <AliceCarouselLink key={index} href={`/announcement/?itemID=${item._id}`}>
      <AnnouncementSlideShowCard item={item} />
    </AliceCarouselLink>
  ));

  return (
    <Container>
      <AliceCarousel
        autoPlayInterval={3000}
        disableButtonsControls={true}
        // disableDotsControls={false}
        infinite={true}
        autoPlay={true}
        mouseTracking={true}
        touchTracking={true}
      >
        {allItems}
      </AliceCarousel>
    </Container>
  );
}
