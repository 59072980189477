import styled from "styled-components";
import { useContext, useRef } from "react";
import Context from "../../../Context.js";
import BrandContainer from "../../brand/BrandContainer.js";
import LoggedInHeaderButton from "./LoggedInHeaderButton.js";
import MembershipMiniInfo from "./MembershipMiniInfo.js";
import { useTranslation } from "react-i18next";
import HelloUserSection from "./announcements/HelloUserSection.js";
import MyActivitiesHeaderButton from "./MyActivitiesHeaderButton.js";
import NavBarWithInvertedCurve from "./NavBarWithInvertedCurve.js";
import { GoHomeFill } from "react-icons/go";
import { IoSettingsSharp } from "react-icons/io5";
import { TbShoppingCartFilled } from "react-icons/tb";
import { RiShoppingBag2Fill } from "react-icons/ri";
import { FaAddressCard } from "react-icons/fa";

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  top: 0;
  position: sticky;
  z-index: 100;
  background-color: var(--rootBackgroundColor);

  @media (max-width: 900px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  padding: 0px 35px;
  border-radius: 10px;
  height: 65px;
  /* background: linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824); */
  /* border: 1px solid #d1da303b; */
  background: var(--navBarBg);
  border: 1px solid #222;
  /* animation: centerScaleReveal 0.4s ease-in; */
  /* background: var(--mainGradient); */

  @media (max-width: 900px) {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 100;
    width: calc(100vw - 20px);
    gap: 0;
    justify-content: space-between;
    box-shadow: 2px -9px 20px 18px #000000;
    border-radius: 15px;
  }

  @media (max-height: 650px) {
    //When keyboard is visible, hide the header
    display: none;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const TopPartMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

const RowMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default function NavBar() {
  const { loggedInUser, isMobile } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUser) return null;

  return (
    <Links>
      <LoggedInHeaderButton link="/" icon={<GoHomeFill />} />
      <LoggedInHeaderButton link="/membership" icon={<FaAddressCard />} />
      <LoggedInHeaderButton link="/shopping" icon={<RiShoppingBag2Fill />} />
      <LoggedInHeaderButton
        badgeCount={getTotalCartItems()}
        link="/cart"
        icon={<TbShoppingCartFilled />}
      />
      <LoggedInHeaderButton link="/settings" icon={<IoSettingsSharp />} />
    </Links>
  );

  function getTotalCartItems() {
    if (!loggedInUser.cart) return 0;

    let totalItems = 0;

    for (let itemID in loggedInUser.cart) {
      let item = loggedInUser.cart[itemID];
      totalItems += item.quantity;
    }

    return totalItems;
  }
}
