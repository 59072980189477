import { useContext, useEffect, useState } from "react";
import ListPage from "../../ListPage";
import ActivityCard from "../ActivityCard";
import DropDownEmployee from "./DropDownEmployee";
import CustomButton from "../../../../helperComponents/CustomButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import Context from "../../../../../Context";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 50px;
`;

export default function UpcomingActivitiesPage() {
  const { isMobile } = useContext(Context);
  const [value, setValue] = useState(null);

  useEffect(() => {
    let urlQuery = getUrlQuery();

    if (urlQuery.employeeID) {
      setValue(urlQuery.employeeID);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <ListPage
      colsOnMobile={2}
      path="/upcoming-activities"
      CardComponent={ActivityCard}
      filterComponent={
        <Row>
          <DropDownEmployee value={value} onChange={setValue} />

          <CustomButton
            style={{ borderRadius: "10px", fontSize: "10px" }}
            textStyle={{ fontSize: isMobile ? "9px" : "12px" }}
            onClick={() => {
              setValue(null);
            }}
          >
            Reset
          </CustomButton>
        </Row>
      }
      title={t("upcomingActivitiesTitle")}
      filterFunction={filterByCoach}
    />
  );

  function filterByCoach(dataList) {
    if (!value) return dataList;

    return dataList.filter((item) => item.employeeID == value);
  }
}
