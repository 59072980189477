import styled from "styled-components";
import LoggedInHeader from "./LoggedInHeader";
import WithBackground from "../../core/boilerplate/WithBackground";
import Footer from "../loggedOut/landingPage/Footer";
import { useContext } from "react";
import Context from "../../../Context";
import InputCard from "../../helperComponents/InputCard";
import PrimaryButton from "../../helperComponents/PrimaryButton";
import goTo from "../../../controllers/goTo";
import BrandContainer from "../../brand/BrandContainer";
import { useTranslation } from "react-i18next";
import LoginWall from "./LoginWall";
import LoadingSection from "../../helperComponents/LoadingSection";
import { motion, AnimatePresence } from "framer-motion";

const Main = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Gap = styled.div`
  display: flex;
  height: 100px;
`;

export default function LoggedInBoilerplate({ children, showFooter }) {
  const { loggedInUserID, loggedInUser } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUserID) return <LoginWall />;

  // return <LoadingSection />;
  return (
    <motion.div
      key="/page"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -100, opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <Main>
        {children}
        <Gap />
        {showFooter ? <Footer /> : null}
      </Main>
    </motion.div>
  );
}
