import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import getImageURL from "../../../../controllers/getImageURL";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import StoreItemImageGallery from "./StoreItemImageGallery";
import fetchDataWithCaching from "../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";
import { IoArrowBack } from "react-icons/io5";
import goTo from "../../../../controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  padding: 40px 0;
  gap: 50px;
  color: #fff;
  width: 90vw;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    /* min-height: 100vh; */
    /* max-height: 100vh; */
    overflow: hidden;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 23px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  text-transform: uppercase;
  margin: 0;
`;

const BackButton = styled.div`
  padding: 8px 20px;
  background: var(--rootBackgroundColor);
  border: 1px solid #333;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  left: 15px;
  color: #666;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopPart = styled.div`
  border-radius: 0 0 10px 10px;
  width: 100%;
`;

const MainText = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border-radius: 10px 10px 0 0; */
`;

const SecondaryTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #555;
  text-align: left;
  margin: 0;
`;

const FloatingButtons = styled.div`
  /* position: fixed;
  bottom: 0;
  left: 0;
  z-index: 55;
  padding: 20px;
  box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
  background-color: var(--rootBackgroundColor);
  width: 100vw; */

  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function StoreItemPageMobile({
  mainButton,
  item,
  details,
  handleRefresh,
}) {
  return (
    <LoggedInBoilerplate>
      <PullToRefresh onRefresh={handleRefresh}>
        <Container>
          <TopPart>
            <StoreItemImageGallery images={item.images} />

            <BackButton onClick={goTo(-1)}>
              <IoArrowBack />
            </BackButton>
          </TopPart>
          <Content>
            <MainText>
              <Title>{item.title}</Title>
              <SecondaryTitle>CFA {item.price}</SecondaryTitle>
            </MainText>

            {details}
          </Content>
          <FloatingButtons>{mainButton}</FloatingButtons>
          <br /> <br />
        </Container>
      </PullToRefresh>
    </LoggedInBoilerplate>
  );
}
