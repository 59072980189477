import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import BigButton from "../BigButton";
import UpcomingActivitiesSection from "../activity/upcomingActivities/UpcomingActivitiesSection";
import AnnouncementSection from "../announcements/AnnouncementSection";
import { useTranslation } from "react-i18next";
import HelloUserSection from "../announcements/HelloUserSection";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import { TbReportMedical } from "react-icons/tb";
import {
  FaBox,
  FaBoxOpen,
  FaFileMedical,
  FaHandHoldingMedical,
} from "react-icons/fa";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { GrAnnounce } from "react-icons/gr";
import { IoMdFitness } from "react-icons/io";
import { IoMedical } from "react-icons/io5";
import { IoIosFitness } from "react-icons/io";
import BrandContainer from "../../../brand/BrandContainer";
import MembershipMiniInfo from "../MembershipMiniInfo";
import MyActivitiesHeaderButton from "../MyActivitiesHeaderButton";
import Footer from "../../loggedOut/landingPage/Footer";
import fetchDataWithCaching from "../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  /* margin-top: 50px; */

  @media (max-width: 900px) {
    gap: 50px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    margin-top: -20px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 30px;
  flex: 1;

  @media (max-width: 900px) {
    gap: 15px;
    margin-top: 15px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: var(--accentColor);
  opacity: 0.8;
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const MobileHomeHeader = styled.div`
  display: none;

  @media (max-width: 900px) {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const MobileRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default function HomePage() {
  const { updateLoggedInUser, updateMembershipPlans } = useContext(Context);
  const [announcements, setAnnouncements] = useState(null);
  const [upcomingActivities, setUpcomingActivities] = useState(null);

  useEffect(() => {
    loadStuff();
  }, []);

  async function loadStuff(skipCache) {
    let data2 = await fetchDataWithCaching({
      path: "/announcements",
      onFreshData: setAnnouncements,
      skipCache,
    });
    setAnnouncements(data2);
    let data3 = await fetchDataWithCaching({
      path: "/upcoming-activities",
      onFreshData: setUpcomingActivities,
      skipCache: skipCache,
    });
    setUpcomingActivities(data3);
    await updateLoggedInUser();
    await updateMembershipPlans();
  }

  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  let announcementButton = (
    <BigButton
      iconImage={"announcements"}
      link="/announcements"
      // icon={<IoMedical />}
    >
      {t("menuItemAnnouncements")}
    </BigButton>
  );

  let shoppingButton = (
    <BigButton
      iconImage={"shopping"}
      link="/shopping"
      // icon={<HiMiniShoppingBag />}
    >
      {t("menuItemShopping")}
    </BigButton>
  );

  let myActivitiesButton = (
    <BigButton
      iconImage={"activities"}
      link="/my-activities"
      // icon={<IoIosFitness />}
    >
      {t("menuItemMyActivities")}
    </BigButton>
  );

  let myOrdersButton = (
    <BigButton
      iconImage={"orders"}
      link="/my-orders"
      // icon={<FaBoxOpen />}
    >
      {t("menuItemMyOrders")}
    </BigButton>
  );

  if (isMobile) {
    announcementButton = null;
    shoppingButton = null;
    myActivitiesButton = null;
    myOrdersButton = null;
  }

  return (
    <PullToRefresh
      // refreshingContent={false}
      onRefresh={loadStuff}
    >
      <LoggedInBoilerplate showFooter={true}>
        <Container>
          <MobileHomeHeader>
            <MobileRow>
              <HelloUserSection />

              <BrandContainer />
            </MobileRow>

            <MobileRow>
              <MembershipMiniInfo />

              <MyActivitiesHeaderButton />
            </MobileRow>
          </MobileHomeHeader>

          <FirstRow>
            <AnnouncementSection selfLoading={false} data={announcements} />

            <Section>
              {/* <Title>{t("menu")}</Title> */}

              <List>
                <BigButton
                  link="/book-health-checkup"
                  iconImage={"book-health-checkup"}
                  // icon={<FaHandHoldingMedical />}
                >
                  {t("menuItemReserveHealthCheckUp")}
                </BigButton>

                <BigButton
                  link="/health-reports"
                  iconImage={"health-reports"}
                  // icon={<FaFileMedical /> }
                >
                  {t("menuItemHealthReports")}
                </BigButton>

                {myActivitiesButton}

                {myOrdersButton}

                {announcementButton}

                {shoppingButton}
              </List>
            </Section>
          </FirstRow>

          <UpcomingActivitiesSection
            selfLoading={false}
            data={upcomingActivities}
          />
        </Container>
      </LoggedInBoilerplate>
    </PullToRefresh>
  );
}
