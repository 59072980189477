import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import calculateAge from "../../../../../controllers/calculateAge";
import parseDate from "../../../../../controllers/parseDate";
import parseExpiresAt from "../../../../../controllers/parseExpiresAt";
import { useState } from "react";
import CustomButton from "../../../../helperComponents/CustomButton";
import MaterialInput from "../../../../helperComponents/MaterialInput";
import DropDownInput from "../../../../helperComponents/DropDownInput";
import { serverLine } from "../../../../../controllers/network/serverLine";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  align-items: center;
  gap: 40px;
  width: auto;
  border-radius: 10px;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ImageSection = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title2 = styled.div``;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
`;

export default function MembershipRequestCard({
  data,
  membershipIdVsPlan,
  membershipPlans,
}) {
  const [dealt, setDealt] = useState(false);
  const [months, setMonths] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selectedMembershipID, setSelectedMembershipPlanID] = useState(
    data.membershipPlanID
  );

  if (!data || dealt) return null;

  let membershipPlanOptions = [];

  for (let item of membershipPlans) {
    if (item.status !== "DISABLED")
      membershipPlanOptions.push({ label: item.name, value: item._id });
  }

  return (
    <Section>
      <TopPart>
        <ImageSection
          src={getImageURL(data.author.profileImage, true)}
        ></ImageSection>

        <Text>
          <Title>
            {data.author.firstName} {data.author.lastName}
          </Title>

          <SubHeading>
            <Title2>{data.author.phoneNumber}</Title2>
            <Title2>Age: {calculateAge(data.author.dateOfBirth)}</Title2>
          </SubHeading>
        </Text>
      </TopPart>

      <Inputs>
        <DropDownInput
          name="Membership Plan"
          value={selectedMembershipID}
          options={membershipPlanOptions}
          onChange={(newID) => {
            setSelectedMembershipPlanID(newID);

            if (months) {
              let thePlan = membershipIdVsPlan[newID];
              setAmount(thePlan.price * months);
            }
          }}
        />

        <MaterialInput
          onChange={({ target }) => {
            setMonths(target.value);

            let newVal = parseInt(target.value);

            if (!isNaN(newVal)) {
              let thePlan = membershipIdVsPlan[selectedMembershipID];
              setAmount(thePlan.price * newVal);
            }
          }}
          label="Validity In Months"
          value={months}
          type={"number"}
        />
        <CustomButton
          onClick={submit}
          style={{ width: "100%", background: "#000" }}
        >
          {amount} Accepted
        </CustomButton>
        <CustomButton
          onClick={deleteItem}
          style={{ width: "100%", background: "#000" }}
        >
          Delete
        </CustomButton>
      </Inputs>
    </Section>
  );

  function submit() {
    function addMonths(months) {
      const result = new Date();
      result.setMonth(result.getMonth() + months);
      result.setHours(0, 0, 0, 0); // Reset time to 00:00:00.000
      return result.toISOString();
    }

    if (!months) return window.popupAlert("Select validity");

    let validity = addMonths(months);

    serverLine.post("/admin/manage-membership-request", {
      requestID: data._id,
      validity,
      action: "ACCEPT",
      amount,
      membershipPlanID: selectedMembershipID,
    });
    window.popupAlert("Done");
    setDealt(true);
  }

  function deleteItem() {
    serverLine.post("/admin/manage-membership-request", {
      requestID: data._id,
      action: "DENY",
    });
    window.popupAlert("Done");
    setDealt(true);
  }
}
