import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton.js";

import { useContext, useEffect, useRef, useState } from "react";
import Context from "../../../../Context.js";
import goTo from "../../../../controllers/goTo.js";
import { useTranslation } from "react-i18next";
import BrandContainer from "../../../brand/BrandContainer.js";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Tagline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const TaglineLine = styled.div`
  color: var(--accentColor);

  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  /* font-style: italic; */

  font-size: 45px;

  width: 100%;
`;

const TaglineLine2 = styled(TaglineLine)`
  /* font-size: 49px; */
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 30px;

  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const Medium = styled.div`
  font-size: 18px;

  opacity: 0.5;

  font-family: "Questrial", serif;

  width: 100%;
  padding: 0;
  font-size: 16px;
  line-height: 23px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
`;

const AnimatedHeroImage = styled.img`
  border-radius: 15px;

  height: 90vh;
  width: 100vw;
  object-fit: cover;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  padding-top: 20px;
  background: linear-gradient(0deg, transparent, 20%, #000, #000);
  height: 200px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  padding: 25px 35px;
  padding-bottom: 20px;
  padding-top: 240px;
  width: 100vw;

  background: linear-gradient(180deg, transparent, 30%, #000);
`;

const SmallText = styled.div`
  font-size: 10px;
  opacity: 0.5;
  margin-top: 15px;
  text-transform: capitalize;
  margin-bottom: -25px;
  width: 100%;
  text-align: left;
`;

export default function LoggedOutHomeForApp() {
  const { isMobile } = useContext(Context);

  const [refreshRef, setRefreshRef] = useState(null);

  const { t } = useTranslation();

  //without this line we will not be able to refresh once the page is changed
  //the above line prevents a buh in which when we go to the login page and backend to the homepage
  //the ref disappears

  useEffect(() => {
    setRefreshRef(true);
  }, []);

  const containerRef = useRef();

  console.log("containerRef.current", containerRef.current);

  return (
    <ParentContainer id="scrollableParent" ref={containerRef}>
      <AnimatedHeroImage src="/hero/guy1.jpg" />

      <Top>
        <BrandContainer />
      </Top>

      <Bottom>
        <TextContainer>
          <Tagline>
            <TaglineLine>{t("loggedOutHeroTitleLine1")}</TaglineLine>
            <TaglineLine2>{t("loggedOutHeroTitleLine2")}</TaglineLine2>
          </Tagline>

          <Medium> {t("loggedOutHeroDesc")} </Medium>

          <br />
          <MainButtons>
            <PrimaryButton
              containerStyle={{ width: "100%" }}
              icon={null}
              onClick={goTo("/signup")}
              style={{
                width: "100%",
                padding: "15px 0",
                borderRadius: "10px",
                boxShadow:
                  "1px 5px 0 0 rgb(96 124 28), 1px 5px 50px 0 rgb(96 124 28)",
              }}
            >
              Commencer
            </PrimaryButton>

            <SmallText>Developpé par Djizef SARL</SmallText>
          </MainButtons>
        </TextContainer>
      </Bottom>
    </ParentContainer>
  );
}
