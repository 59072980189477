import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import { useTranslation } from "react-i18next";
import { IoArrowBack } from "react-icons/io5";
import goTo from "../../../../../controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0;
  background-color: #000;
  color: #fff;
  width: 97vw;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 23px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  text-transform: uppercase;
  font-style: italic;
  margin: 0;
`;

const Image = styled.img``;

const BackButton = styled.div`
  padding: 8px 20px;
  background: var(--rootBackgroundColor);
  border: 1px solid #333;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  left: 15px;
  color: #666;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopPart = styled.div`
  border-radius: 0 0 10px 10px;
  width: 100%;
`;

const MainText = styled.div`
  border-top: 1px solid #333;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px 10px 0 0;
`;

const SecondaryTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #555;
  text-align: left;
  margin: 0;
`;

const FloatingButtons = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 55;
  padding: 20px;
  box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
  background-color: var(--rootBackgroundColor);
  width: 100vw;
`;

const ParticipationType = styled.div`
  padding: 8px 20px;
  background: var(--rootBackgroundColor);
  border: 1px solid #333;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  display: flex;
  justify-content: center;

  align-items: center;

  font-size: 12px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: #555;
  text-align: left;
  margin: 0;
  text-transform: capitalize;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

function ActivityDetailsMobile({
  item,
  state,
  theButton,
  textContent,
  showReportButton,
}) {
  const { t } = useTranslation();

  return (
    <Container>
      <TopPart>
        <Image src={getImageURL(item.activityType.image)} />

        <BackButton onClick={goTo(-1)}>
          <IoArrowBack />
        </BackButton>

        {item.participationType ? (
          <ParticipationType>
            {item.participationType.toLowerCase()}
          </ParticipationType>
        ) : null}

        <MainText>
          <Title>{item.activityType.title}</Title>
          <SecondaryTitle>
            {state.seats - state.seatsBooked} Seats Left
          </SecondaryTitle>
        </MainText>
      </TopPart>
      <FloatingButtons>
        {showReportButton}
        {theButton}
      </FloatingButtons>
      <Content>{textContent}</Content>
      <br /> <br /> <br />
    </Container>
  );
}

export default ActivityDetailsMobile;
