import styled from "styled-components";
import CustomButton from "../../../../helperComponents/CustomButton";
import BigToggle from "../../../../helperComponents/BigToggle";
import getDateWithSlash from "../../../../../controllers/getDateWithSlash";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Context from "../../../../../Context";
import daysArray from "../../../../../data/daysArray";
const Title = styled.div`
  font-size: 18px;
  color: var(--accentColor);
`;

export default function ScheduleDatePicker({
  value,
  onChange,
  label,
  forHealthCheckup,
}) {
  const { adminSettings } = useContext(Context);
  let allDates = getNextWeekDates();

  const { t } = useTranslation();

  return (
    <BigToggle
      title={label ? label : "Select Date"}
      options={allDates.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    let allowedDate = thisDateIsAvailable(item.getDay());

    if (!allowedDate)
      return {
        onClick: null,
        isSelected: false,
        label: formatDateToDayMonth(item),
      };

    return {
      onClick: () => {
        onChange(getDateWithSlash(item));
      },
      isSelected: value == getDateWithSlash(item),
      label: formatDateToDayMonth(item),
    };
  }

  function thisDateIsAvailable(theDay) {
    let theDateStr = daysArray[theDay];

    console.log(theDateStr);

    if (!theDateStr) return true;

    if (!forHealthCheckup) return true;

    if (adminSettings) {
      let data = adminSettings.data;

      if (data.healthCheckupAvailableDays) {
        let theList = data.healthCheckupAvailableDays;
        if (!theList.includes(theDateStr)) return false;
      }
    }

    return true;
  }
}

function formatDateToDayMonth(dateObj) {
  // Get the day of the month
  const day = dateObj.getDate();

  // Get the month name (short format)
  const month = dateObj.toLocaleString("en-US", { month: "short" });

  // Format and return the string as "12 Jun"
  return `${day} ${month}`;
}

function getNextWeekDates() {
  let dates = [];

  let currentDate = new Date();
  // Loop through the next 7 days, including the current day
  for (let i = 1; i <= 5; i++) {
    let nextDate = new Date(); // Copy the current date
    nextDate.setDate(currentDate.getDate() + i); // Add 'i' days
    dates.push(setToMidnight(nextDate));
  }

  return dates;
}

function setToMidnight(dateObj) {
  // Set the time to 12:00 AM (00:00 hours)
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
}
