import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { serverLine } from "../../../../controllers/network/serverLine";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
  /* padding-right: 50px; */

  color: #ffffff;

  gap: 30px;
  font-weight: bold;
  font-size: 20px;

  padding: 20px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const Features = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0.8;
  flex-direction: column;
`;

const PlanName = styled.span`
  color: var(--accentColor);
  font-size: 19px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
`;

const PriceTag = styled.div`
  color: var(--accentColor);
  font-size: 13px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const MembershipSelectionCard = ({
  onChange,
  price,
  title,
  desc,
  name,
  features,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      onClick={() => {
        if (onChange) onChange(name);
      }}
    >
      <CardTitle>
        <PlanName>{title}</PlanName>
        <PriceTag>{price}</PriceTag>
      </CardTitle>
      <PlanDescription>{desc}</PlanDescription>
      {/* <PriceTag>{price}</PriceTag> */}
      <Features>{features}</Features>
    </Card>
  );
};

export default MembershipSelectionCard;
