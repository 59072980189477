import { Preferences } from "@capacitor/preferences";
import goTo from "./goTo";

async function logout() {
  await Preferences.clear();
  //Removes cache and

  // window.location = window.location.origin;

  window.setTimeout(() => {
    goTo("/")();
    window.doInitialLoad();
  }, 300);

  // window.setTimeout(() => {
  //   goTo("/")();
  // }, 500);

  // serverLine.delete("/cookie").then(() => {
  // window.location = window.location.origin;
  // });
}

export default logout;
