import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../controllers/getImageURL";
import SemanticButton from "../../../helperComponents/SemanticButton";
import { useTranslation } from "react-i18next";
import limitStringLength from "../../../../controllers/limitStringLength";
import CustomButton from "../../../helperComponents/CustomButton";
import Context from "../../../../Context";

const Card = styled.div`
  /* border: 1px solid #222; */
  /* background-color: var(--highlightBackgroundDark); */

  border-radius: 0;
  width: 600px;
  height: 320px;
  margin-left: 0;
  overflow: hidden;
  color: white;
  position: relative;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 89vw;
    height: 230px;
    margin-left: 0;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  padding: 20px;
  padding-top: 150px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(1deg, black, 50%, transparent);
  width: 100%;
  left: 0;
  border-radius: 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 400;
  opacity: 0.5;
  text-transform: capitalize;
  color: #c9d400;
  margin: 0;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function AnnouncementSlideShowCard({ item }) {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  let btnStyle = { marginLeft: "10px" };

  if (isMobile) {
    btnStyle.marginLeft = "1vw";
  }

  return (
    <CustomButton
      style={{
        padding: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Card>
        <SemanticButton href={`/announcement/?itemID=${item._id}`}>
          <ImageContainer>
            <Image src={getImageURL(item.image)} alt="" />
          </ImageContainer>
          <InfoContainer>
            <Title>{item.title}</Title>{" "}
            {/* <Description>{item.description}</Description> */}
          </InfoContainer>
        </SemanticButton>
      </Card>{" "}
    </CustomButton>
  );
}
