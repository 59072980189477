import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import { useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import getImageURL from "../../../../controllers/getImageURL";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../helperComponents/TitleBar";
import fetchDataWithCaching from "../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 90vw;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 0;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 900px) {
    gap: 10px;
    max-width: 90vw;
  }
`;

const Title = styled.h1`
  font-size: 23px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  text-transform: uppercase;
  margin: 0;
`;

const Desc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucent);
  border: var(--highlightBorder);
  border-radius: 10px;
  overflow: hidden;
`;

export default function AnnouncementPage() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    doLoading();
  }, []);

  async function doLoading(skipCache) {
    let urlQuery = getUrlQuery();
    let data = await fetchDataWithCaching({
      path: `/announcement/?itemID=${urlQuery.itemID}`,
      setLoading,
      skipCache,
      onFreshData: setItem,
    });
    setItem(data);
  }

  async function handleRefresh() {
    await doLoading(true);
  }

  if (loading)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (!item) return null;

  return (
    <LoggedInBoilerplate>
      <TitleBar>{t("announcementPageTitle")}</TitleBar>

      <PullToRefresh onRefresh={handleRefresh}>
        <Container>
          <InfoSection>
            <Title>{item.title}</Title>
            <Desc>{item.description}</Desc>
          </InfoSection>

          <RightSection>
            <ImageSection>
              <Image src={getImageURL(item.image)} />
            </ImageSection>
          </RightSection>
        </Container>
      </PullToRefresh>
    </LoggedInBoilerplate>
  );
}
