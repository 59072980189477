import { GrGoogle } from "react-icons/gr";
import CustomButton from "./CustomButton";
import { styled } from "styled-components";
import doGoogleLogin from "../../utils/loginWithGoogle/doGoogleLogin";
import { useState } from "react";

const Container = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export default function LoginButton() {
  const [loading, setLoading] = useState(false);
  return (
    <CustomButton
      icon={<GrGoogle />}
      customVariant="slightlyContained"
      circular={true}
      style={{ textTransform: "capitalize" }}
      onClick={doGoogleLogin({ setLoading })}
    >
      {loading ? "Loading...." : "Login With Google"}
    </CustomButton>
  );
}
