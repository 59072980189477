import styled from "styled-components";
import CustomButton from "../../../helperComponents/CustomButton";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import universalLocalStorage from "../../../../utils/lib/universalLocalStorage";
import Context from "../../../../Context";

const Container = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #111;
  align-items: center;
  margin-top: 200px;
  /* position: ; */

  @media (max-width: 900px) {
    margin-top: 0;
    padding-bottom: 150px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-transform: capitalize;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const RowTitle = styled.div`
  font-size: 17px;
  display: none;
  font-weight: 900;
`;

let variant = null;

export default function Footer() {
  const { isMobile } = useContext(Context);
  const [lang, setLang] = useState(null);

  useEffect(() => {
    universalLocalStorage.getItem("langPreference").then(setLang);
  }, []);

  const { t } = useTranslation();
  let theLang = lang ? lang : "fr";

  console.log(theLang);

  let date = new Date();

  let btnStyle = { fontSize: "8px" };

  if (!isMobile) btnStyle = { fontSize: "9px" };

  return (
    <Container>
      <Row>
        {/* <RowTitle>Useful Links</RowTitle> */}
        <Links>
          <CustomButton
            textStyle={btnStyle}
            variant={variant}
            onClick={changeLanguage}
          >
            {theLang == "fr" ? "Switch to English" : "Passer au français"}{" "}
          </CustomButton>

          <CustomButton
            textStyle={btnStyle}
            variant={variant}
            href="/privacy-policy"
          >
            {t("footerSectionPrivacyPolicy")}
          </CustomButton>
          <CustomButton
            textStyle={btnStyle}
            variant={variant}
            href="/terms-and-conditions"
          >
            {t("footerSectionTermsAndConditions")}
          </CustomButton>
          <CustomButton
            textStyle={btnStyle}
            variant={variant}
            href="/refund-policy"
          >
            {t("footerSectionRefundPolicy")}
          </CustomButton>
        </Links>
      </Row>
      <br />
      <Row>Copyright © {date.getFullYear()} Wellness Care Center</Row>

      <Row>Developpé par Djizef SARL</Row>
    </Container>
  );

  async function changeLanguage() {
    await universalLocalStorage.setItem(
      "langPreference",
      theLang == "fr" ? "en" : "fr"
    );
    window.location.reload();
  }
}
