import styled from "styled-components";

import { MdArrowUpward, MdRemove } from "react-icons/md";
import { FaHamburger } from "react-icons/fa";
import { PiHamburger } from "react-icons/pi";
import { HiOutlineMenu } from "react-icons/hi";

import extractEventValue from "../../../../controllers/utils/extractEventValue";
import CustomButton from "../../../helperComponents/CustomButton";
import MaterialInput from "../../../helperComponents/MaterialInput";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 25px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  gap: 10px;
`;

const Title = styled.span`
  white-space: nowrap;
  font-size: 21px;
  overflow: hidden;
  flex: 1;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CategoryItemEditor({
  item,
  onRemove,
  onChange,
  index,
  moveUp,
}) {
  const { t } = useTranslation();

  return (
    <Container>
      <Left>
        <Title>
          <MaterialInput
            value={item.name}
            placeholder={"Category Name"}
            label={t("adminPageStoreCategoryName")}
            onChange={extractEventValue(updateName)}
          />
        </Title>
      </Left>

      <CustomButton
        customVariant="slightlyContained"
        height={"30px"}
        onClick={() => {
          onRemove(item._id);
        }}
        icon={<MdRemove />}
      />

      <CustomButton
        customVariant="slightlyContained"
        height={"30px"}
        onClick={() => {
          moveUp(index);
        }}
        icon={<MdArrowUpward />}
      />
    </Container>
  );

  function updateName(newName) {
    let newItem = { ...item };
    newItem.name = newName;
    console.log(newName, newItem);
    onChange(newItem, index);
  }
}
