import { useState } from "react";
import WithBackground from "../../../core/boilerplate/WithBackground";
import CustomToggle from "../../../helperComponents/CustomToggle";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import LoggedOutHeader from "../landingPage/LoggedOutHeader";
import styled from "styled-components";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import InputCard from "../../../helperComponents/InputCard";
import CustomButton from "../../../helperComponents/CustomButton";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import MessageBox from "../../../helperComponents/MessageBox";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/network/serverLine";
import saveUserAuth from "../../../../controllers/auth/saveUserAuth";
import { useTranslation } from "react-i18next";

const options = [
  { value: "LOGIN", label: "Login" },
  { value: "SIGNUP", label: "Signup" },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-end;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 23px;
  color: var(--accentColor);
`;

const Medium = styled.div`
  /* font-weight: 900; */
  opacity: 0.4;
  font-size: 15px;
  text-align: center;
  color: var(--accentColor);
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
`;

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState();
  const { t } = useTranslation();

  let core = (
    <>
      <MaterialInput
        label={t("password")}
        type="password"
        // placeholder={"Type Password Here"}
        value={password}
        onChange={extractEventValue(setPassword)}
      />

      <MaterialInput
        label={t("confirmPassword")}
        type="password"
        // placeholder={"Type Confirm Password Here"}
        value={confirmPassword}
        onChange={extractEventValue(setConfirmPassword)}
      />
    </>
  );

  if (loading) core = <LoadingSection />;

  if (done) core = <MessageBox>{t("yourPasswordHasBeenChanged")}</MessageBox>;

  return (
    <WithBackground backgroundImage="/dumbbells.jpg">
      <Container>
        <LoggedOutHeader />

        <InputCard
          title={t("changePasswordTitle")}
          // desc="Type in your new password"
          submitButtonText={t("changePasswordSubmit")}
          onSubmit={changePassword}
        >
          {core}
        </InputCard>
      </Container>
    </WithBackground>
  );

  async function changePassword() {
    let urlQueries = getUrlQuery();
    let email = urlQueries.email;
    let code = urlQueries.code;

    setLoading(true);

    try {
      let data = await serverLine.post("/change-password", {
        email,
        code,
        password,
        confirmPassword,
      });
      setLoading(false);
      saveUserAuth(data);
      setDone(true);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
