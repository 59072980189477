import styled from "styled-components";
import MaterialInput from "../../../../helperComponents/MaterialInput";
import CustomToggle from "../../../../helperComponents/CustomToggle";
import { useContext, useState } from "react";
import Context from "../../../../../Context";
import CustomButton from "../../../../helperComponents/CustomButton";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

let typeOptions = [
  { label: "All", value: "ALL" },
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
];

export default function FilterUser({ filter, setFilter, membershipPlans }) {
  const [newFilter, setNewFilter] = useState(filter);

  const { setForm } = useContext(Context);

  let nullPlan = { value: "ALL", label: "All" };

  let allPlans = membershipPlans.map((item) => {
    return { label: item.name, value: item._id };
  });

  allPlans = [nullPlan, ...allPlans];

  return (
    <Container>
      <MaterialInput
        type="number"
        label={"Min Age"}
        value={newFilter.minAge}
        onChange={updateField("minAge", true)}
      />
      <MaterialInput
        type="number"
        label={"Max Age"}
        value={newFilter.maxAge}
        onChange={updateField("maxAge", true)}
      />
      <CustomToggle
        value={newFilter.gender}
        onChange={updateField("gender")}
        options={typeOptions}
      />

      <CustomToggle
        value={newFilter.membershipPlanID}
        onChange={updateField("membershipPlanID")}
        options={allPlans}
      />

      <CustomButton onClick={loadNewData} style={{ width: "200px" }}>
        Set Filter
      </CustomButton>
    </Container>
  );

  function loadNewData() {
    setFilter(newFilter);
    setForm(null);
  }

  function updateField(fieldName, isEvent) {
    return (data) => {
      if (isEvent) {
        data = data.target.value;
      }

      let newData = { ...newFilter };
      newData[fieldName] = data;
      setNewFilter(newData);
    };
  }
}
