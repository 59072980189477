import styled from "styled-components";
import Link from "../../helperComponents/Link.js";
import { useTranslation } from "react-i18next";

const Img = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;
  object-fit: contain;
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
`;

const Badge = styled.div`
  position: absolute;
  background-color: var(--accentColor);
  padding: 2px 5px;
  color: var(--bgColor);
  font-weight: 900;
  font-size: 12px;
  border-radius: 100px;
  right: -20px;
  bottom: 5px;
`;

const Div = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 25px;
  opacity: 0.9;
  /* animation: centerScaleReveal 0.6s ease-in; */

  ${({ isSelected }) => {
    if (isSelected) {
      return `
      color: var(--accentColor);
      opacity:1;
      `;
    }
  }}
`;

export default function LoggedInHeaderButton({
  link,
  icon,
  activeIcon,
  badgeCount,
}) {
  let isSelected = checkIsSelected();
  const { t } = useTranslation();

  return (
    <Link href={link}>
      <Div isSelected={isSelected}>
        {badgeCount ? <Badge>{badgeCount}</Badge> : null}
        {icon}
      </Div>
    </Link>
  );

  function checkIsSelected() {
    let current = window.location.pathname;

    if (link == "/") {
      if (current == "/") return true;

      return false;
    }

    if (current.indexOf(link) !== -1) return true;

    return false;
  }
}
