import { useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/network/serverLine";
import SearchUserByEmailBox from "./SearchUserByEmailBox";
import styled from "styled-components";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import PaymentHistoryTable from "./PaymentHistoryTable";
import UserAndMembershipBox from "./UserAndMembershipBox";
import CreatePaymentEntryBox from "./CreatePaymentEntryBox";
import AdminPageBoilerplate from "../AdminPageBoilerplate";
import { t } from "i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 62vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function ManagePaymentEntry() {
  const [membershipPlans, setMembershipPlans] = useState(null);
  const [data, setData] = useState(null);
  const [emailQuery, setEmailQuery] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    serverLine.get("/membership-plans").then(setMembershipPlans);
  }, []);

  if (!membershipPlans || loading)
    return (
      <AdminPageBoilerplate title={t("adminPagePaymentEntryTitle")}>
        <LoadingSection />
      </AdminPageBoilerplate>
    );

  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  return (
    <AdminPageBoilerplate title={t("adminPagePaymentEntryTitle")}>
      <Container>
        <SearchUserByEmailBox
          emailQuery={emailQuery}
          setEmailQuery={setEmailQuery}
          findUser={findUser}
        />

        {getMain()}
      </Container>
    </AdminPageBoilerplate>
  );

  function getMain() {
    if (!data) return null;
    if (loading) return <LoadingSection />;

    return (
      <>
        <UserAndMembershipBox
          data={data}
          membershipIdVsPlan={membershipIdVsPlan}
        />

        <CreatePaymentEntryBox
          membershipIdVsPlan={membershipIdVsPlan}
          membershipPlans={membershipPlans}
          setLoading={setLoading}
          data={data}
          findUser={findUser}
        />

        <PaymentHistoryTable
          data={data.paymentHistory}
          membershipIdVsPlan={membershipIdVsPlan}
        />
      </>
    );
  }

  async function findUser() {
    if (loading) return null;

    try {
      setLoading(true);
      let data = await serverLine.get(
        `/admin/payment-history-and-membership/?emailID=${emailQuery}`
      );
      setData(data);
      setLoading(false);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
