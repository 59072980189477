import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/network/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import getImageURL from "../../../../controllers/getImageURL";
import calculateAge from "../../../../controllers/calculateAge";
import OrderCard from "../shop/OrderCard";
import ActivityBookingCard from "../activity/ActivityBookingCard";
import CustomButton from "../../../helperComponents/CustomButton";
import { AiOutlineLeft } from "react-icons/ai";
import goTo from "../../../../controllers/goTo";
import getMembershipPlanName from "../../../../controllers/getMembershipPlanName";
import parseDate from "../../../../controllers/parseDate";
import TitleBar from "../../../helperComponents/TitleBar";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  gap: 50px;

  color: #fff;
  width: 90vw;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const InfoSection = styled.div`
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.div`
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--accentColor);
  font-family: "Montserrat", sans-serif;
`;

const Title2 = styled.div`
  font-size: 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const ImageSection = styled.img`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  object-fit: cover;
  height: 300px;
  width: 300px;
`;

const SmallHeading = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--accentColor);
  font-family: "Montserrat", sans-serif;
`;

const Section = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
`;

export default function UserPage() {
  const { loggedInUser, membershipPlans, updateMembershipPlans } =
    useContext(Context);
  const { t } = useTranslation();
  const [item, setItem] = useState(null);

  useEffect(() => {
    updateMembershipPlans();
  }, []);

  useEffect(() => {
    let lastPart = window.location.pathname.split("/");
    let username = lastPart[lastPart.length - 1];
    serverLine.get(`/user/?username=${username}`).then(setItem);
  }, []);

  if (!item || !loggedInUser || !membershipPlans)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  return (
    <LoggedInBoilerplate>
      <TitleBar onlyMobile={true}> {item.userData.firstName} </TitleBar>
      <Container>
        <InfoSection>
          <CustomButton
            style={{ opacity: 1, width: "140px" }}
            onlyDesktop={true}
            onClick={goTo(-1)}
            icon={<AiOutlineLeft />}
          >
            Back
          </CustomButton>
          <Section>
            <Title>
              {item.userData.firstName} {item.userData.lastName}
            </Title>

            <Info>
              <Title2>{item.userData.email}</Title2>
              <Title2>{item.userData.phoneNumber}</Title2>

              <Title2>
                Membership Plan:{" "}
                {getMembershipPlanName({
                  membershipIdVsPlan,
                  membershipPlanID: item.userData.membershipPlanID,
                })}
              </Title2>
              <Title2>
                Membership Validity:{" "}
                {parseDate(item.userData.membershipPlanValidity)}
              </Title2>

              <Title2>Age: {calculateAge(item.userData.dateOfBirth)}</Title2>
            </Info>
          </Section>

          <Section>
            <SmallHeading>Activities</SmallHeading>

            <List>
              {item.activityBookings.map((item) => (
                <ActivityBookingCard item={item} />
              ))}
            </List>
          </Section>

          <Section>
            <SmallHeading>Shopping History</SmallHeading>
            <List>
              {item.orders.map((item) => (
                <OrderCard item={item} />
              ))}
            </List>
          </Section>
        </InfoSection>

        <RightSection>
          <ImageSection
            src={getImageURL(item.userData.profileImage, true)}
          ></ImageSection>
        </RightSection>
      </Container>
    </LoggedInBoilerplate>
  );
}
