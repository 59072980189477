import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";
import sendGoogleAccessToken from "./sendGoogleAccessToken";

export default function doGoogleLogin({ setLoading }) {
  return async () => {
    setLoading(true);
    const platform = Capacitor.getPlatform();

    console.log(platform);

    try {
      await doAppAuth();
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  };
}

async function doAppAuth() {
  const response = await GoogleAuth.signIn();
  console.log(response);
  let refreshData = await GoogleAuth.refresh();

  sendGoogleAccessToken(refreshData.accessToken);
}
