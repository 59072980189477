import { useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/network/serverLine";
import SearchUserByEmailBox from "../paymentEntry/SearchUserByEmailBox";
import styled from "styled-components";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import PaymentHistoryTable from "../paymentEntry/PaymentHistoryTable";
import UserAndMembershipBox from "../paymentEntry/UserAndMembershipBox";
import CreatePaymentEntryBox from "../paymentEntry/CreatePaymentEntryBox";
import AdminPageBoilerplate from "../AdminPageBoilerplate";
import { t } from "i18next";
import MembershipRequestCard from "./MembershipRequestCard";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  /* width: 62vw; */

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

export default function ManageMembershipRequests() {
  const [membershipPlans, setMembershipPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [membershipRequests, setMembershipRequests] = useState(null);

  useEffect(() => {
    serverLine.get("/membership-plans").then(setMembershipPlans);
    serverLine.get("/admin/membership-requests").then(setMembershipRequests);
  }, []);

  if (!membershipPlans || loading || !membershipRequests)
    return (
      <AdminPageBoilerplate title={t("adminPageMembershipReqTitle")}>
        <LoadingSection />
      </AdminPageBoilerplate>
    );

  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  return (
    <AdminPageBoilerplate title={t("adminPageMembershipReqTitle")}>
      <Container>
        {membershipRequests.map((item) => (
          <MembershipRequestCard
            membershipPlans={membershipPlans}
            data={item}
            membershipIdVsPlan={membershipIdVsPlan}
          />
        ))}
      </Container>
    </AdminPageBoilerplate>
  );
}
