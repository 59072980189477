import { useEffect, useState } from "react";

import CustomDateInput from "../../../helperComponents/CustomDateInput";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import MessageBox from "../../../helperComponents/MessageBox";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/network/serverLine";
import getUrlQuery from "../../../../controllers/getUrlQuery";
import saveUserAuth from "../../../../controllers/auth/saveUserAuth";
import { useTranslation } from "react-i18next";

export default function VerifyEmail() {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { t } = useTranslation();
  let sentByInbox = false;
  if (window.location.href.indexOf("confirm-email") !== -1) sentByInbox = true;

  useEffect(() => {
    if (sentByInbox) verifyEmail();
  }, [sentByInbox]);

  let btnText = "Resend";

  let core = (
    <MessageBox>
      {t("onboardingVerifyEmailDesc")}
      {/* <br /> <br /> */}
      {/* {t("onboardingVerifyEmailDesc2")} */}
    </MessageBox>
  );

  if (loading) {
    core = <LoadingSection />;
    btnText = "";
  }

  if (done) {
    btnText = "";

    core = <MessageBox>{t("onboardingVerifyEmailSent")}</MessageBox>;
  }

  if (sentByInbox)
    return (
      <OnboardingBoilerplate title={t("onboardingVerifyEmailWhileConfirming")}>
        <LoadingSection />
      </OnboardingBoilerplate>
    );

  return (
    <OnboardingBoilerplate
      title={t("onboardingVerifyEmailMainTitle")}
      desc=""
      onSubmit={resend}
      submitButtonText={btnText}
    >
      {core}
    </OnboardingBoilerplate>
  );

  async function verifyEmail() {
    setLoading(true);

    try {
      let urlQueries = getUrlQuery();
      let email = urlQueries.email;
      let code = urlQueries.code;
      let data = await serverLine.post("/confirm-email", { email, code });
      saveUserAuth(data);
      setLoading(false);
      setDone(true);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  async function resend() {
    setLoading(true);

    try {
      await serverLine.post("/resend-email-for-confirmation");
      setLoading(false);
      setDone(true);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
