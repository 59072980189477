import styled from "styled-components";

const Container = styled.div`
  padding: 15px;
  width: 38vw;
  text-align: center;
  border-radius: 10px;
  background: var(--translucentLight);
  color: var(--accentColor);

  @media (max-width: 900px) {
    width: 80vw;
  }
`;

export default function MessageBox({ children, style }) {
  return <Container style={style}>{children}</Container>;
}
