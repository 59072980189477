import "./App.css";
import "./aliceCarousel.css";
import "./pullToRefreshStyle.css";
// import "@ionic/react/css/core.css";
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Palette
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

import "@ionic/react/css/palettes/dark.always.css";

import "./ionicTheme.css";

import { ThemeProvider, createTheme } from "@mui/material/styles/index.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomRouter from "./components/core/CustomRouter.js";
import Boilerplate from "./components/core/boilerplate/Boilerplate.js";
import { App as CapacitorApp } from "@capacitor/app";
import { setupIonicReact } from "@ionic/react";
import goTo from "./controllers/goTo.js";
import LoggedInHeader from "./components/pages/loggedIn/LoggedInHeader.js";
import styled from "styled-components";

setupIonicReact({ mode: "ios" });

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    goTo(-1)();
  }
});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#D1DA30",
      dark: "#c9ff00",
    },
    secondary: {
      main: "#ddf500",
    },
  },
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: unset;
  height: auto;
  align-items: center;
`;
//Material Theme made using: https://zenoo.github.io/mui-theme-creator/

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Boilerplate>
          <LoggedInHeader />
          <CustomRouter />
        </Boilerplate>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
