import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/network/serverLine";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import Section from "../../../../helperComponents/Section";
import styled from "styled-components";
import CustomButton from "../../../../helperComponents/CustomButton";
import ActivityCard from "../ActivityCard";
import { useTranslation } from "react-i18next";
import CustomMasonry from "../../../../helperComponents/CustomMasonry";
import { FaAngleRight } from "react-icons/fa";
import Context from "../../../../../Context";

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  font-size: 18px;
  color: var(--accentColor);
  filter: saturate(0);
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;

  @media (max-width: 900px) {
    font-size: 14px;
    opacity: 0.7;
  }
`;

const TopRight = styled.div``;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ScrollableRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 90vw;
  overflow-y: auto;
  height: 50vw;
`;

export default function UpcomingActivitiesSection({ data }) {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();

  let items = data;

  if (!items) return <LoadingSection />;

  let allItems = items.map((item) => <ActivityCard item={item} />);

  let mainContent = (
    <CustomMasonry maxCol={4} list={allItems} colsOnMobile={2}></CustomMasonry>
  );

  if (isMobile)
    mainContent = (
      <ScrollableRow className="scrollableRow">{allItems}</ScrollableRow>
    );

  return (
    <Container>
      <Top>
        <TopLeft>{t("upcomingActivitiesTitle")}</TopLeft>
        <TopRight>
          <CustomButton
            iconStyle={{ fontSize: "16px" }}
            icon={<FaAngleRight />}
            textStyle={{
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: "14px",
            }}
            variant={"text"}
            href="/upcoming-activities"
            style={{ flexDirection: "row-reverse", padding: 0 }}
          >
            {t("upcomingActivitiesShowAll")}{" "}
          </CustomButton>
        </TopRight>
      </Top>
      {mainContent}
    </Container>
  );
}
