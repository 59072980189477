import styled from "styled-components";
import LoggedInBoilerplate from "../../LoggedInBoilerplate";
import { useContext, useEffect, useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../../controllers/network/serverLine";
import getUrlQuery from "../../../../../controllers/getUrlQuery";
import formatDate from "../../../../../controllers/formatDate";
import goTo from "../../../../../controllers/goTo";
import getImageURL from "../../../../../controllers/getImageURL";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";
import HealthReportButton from "../../HealthReportButton";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "../scheduleCheckup/ExactSlotTime";
import TitleBar from "../../../../helperComponents/TitleBar";
import fetchDataWithCaching from "../../../../../controllers/network/fetchDataWithCaching";
import PullToRefresh from "react-simple-pull-to-refresh";
import Context from "../../../../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* align-items: center; */
  width: 90vw;
  padding: 20px 0;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: 50px;
    justify-content: flex-start;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 900px) {
    display: none;
  }
`;

const ReportIsPending = styled.div`
  font-size: 14px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(--accentColor);
  text-align: left;
  font-style: italic;
  margin: 0;
  text-transform: uppercase;
  background: var(--highlightBackground);
  padding: 10px;
  border-radius: 10px;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Medium = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const SmallTitle = styled.div`
  font-size: 14px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;
`;

const ReportData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 600px;
  gap: 10px;

  @media (max-width: 900px) {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 50vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  @media (max-width: 900px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 55;
    padding: 20px;
    box-shadow: 0 -20px 30px 20px var(--rootBackgroundColor);
    background-color: var(--rootBackgroundColor);
    width: 100vw;
  }
`;

export default function HealthReportPage() {
  const { isMobile } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    doLoading();
  }, []);

  async function doLoading(skipCache) {
    let urlQuery = getUrlQuery();
    let newData = await fetchDataWithCaching({
      path: `/health-checkup-booking/?itemID=${urlQuery.itemID}`,
      skipCache,
      setLoading,
      onFreshData: setItem,
    });
    setItem(newData);
  }

  async function handleRefresh() {
    await doLoading(true);
  }

  if (loading)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  if (!item) return null;

  let reportFileButton = null;
  let reportData = null;

  if (item.reportFile) {
    reportFileButton = (
      <PrimaryButton
        style={{ width: isMobile ? "100%" : "300px", borderRadius: "10px" }}
        onClick={goTo(getImageURL(item.reportFile))}
      >
        {t("healthReportItemOpenReport")}
      </PrimaryButton>
    );
  }

  if (item.reportData) {
    reportData = (
      <>
        <HealthReportButton
          icon="/weight.png"
          title={t("healthReportItemWeight")}
        >
          {item.reportData.weight} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/lean-body-mass.png"
          title={t("healthReportItemLearnBodyMass")}
        >
          {item.reportData.leanBodyMass} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/muscle-mass.png"
          title={t("healthReportItemMuscleMass")}
        >
          {item.reportData.muscleMass} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/body-water.png"
          title={t("healthReportItemBodyWater")}
        >
          {item.reportData.bodyWater} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/body-fat-mass.png"
          title={t("healthReportItemBodyFatMass")}
        >
          {item.reportData.bodyFatMass} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/inorganic-salts.png"
          title={t("healthReportItemInorganicSalt")}
        >
          {item.reportData.inorganicSalts} lbs
        </HealthReportButton>
        <HealthReportButton
          icon="/protein.png"
          title={t("healthReportItemProtein")}
        >
          {item.reportData.protein} lbs
        </HealthReportButton>
      </>
    );
  }

  return (
    <LoggedInBoilerplate>
      <TitleBar onlyMobile={true}>
        {t("healthReportItemTitle")} {formatDate(item.date)}
      </TitleBar>
      <PullToRefresh onRefresh={handleRefresh}>
        <Container>
          <Left>
            <Title>
              {t("healthReportItemTitle")} {formatDate(item.date)}
            </Title>
            <Section>
              <Info>
                <Medium>
                  {t("healthReportItemDate")}: {formatDate(item.date)}
                </Medium>

                <Medium>
                  {t("healthReportItemTime")}:{" "}
                  <ExactSlotTime slot={item.slot} hour={item.hour} />
                </Medium>

                <Medium>
                  {t("healthReportItemStatus")}:{" "}
                  {capitalizeFirstLetter(item.status.toLowerCase())}
                </Medium>
              </Info>
            </Section>

            <Section>
              <SmallTitle> {t("healthReportItemRemark")}</SmallTitle>

              <Info>
                <Medium>
                  {item.remark ? item.remark : "Remark Not Available"}
                </Medium>
              </Info>
            </Section>

            {reportFileButton ? <Buttons>{reportFileButton}</Buttons> : null}
          </Left>

          {reportData ? (
            <ReportData>{reportData}</ReportData>
          ) : (
            <ReportIsPending>Report is pending</ReportIsPending>
          )}
        </Container>
      </PullToRefresh>
    </LoggedInBoilerplate>
  );
}
