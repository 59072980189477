import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import parseDate from "../../../../../controllers/parseDate";
import parseExpiresAt from "../../../../../controllers/parseExpiresAt";

export default function PaymentHistoryTable({ data, membershipIdVsPlan }) {
  if (!data) return null;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Plan</TableCell>
            <TableCell>Validity</TableCell>
            <TableCell>Amount</TableCell>

            <TableCell>Created At</TableCell>
            <TableCell>Expires in</TableCell>
            <TableCell>Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              <TableCell component="th" scope="row">
                {getPlanName(row)}
              </TableCell>
              <TableCell>{parseDate(row.validity)} </TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{parseDate(row.createdAt)} </TableCell>
              <TableCell>{parseExpiresAt(row.validity)} </TableCell>
              <TableCell>{row.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  function getPlanName(row) {
    if (!membershipIdVsPlan[row.membershipPlanID]) {
      return "Undefined";
    }

    return membershipIdVsPlan[row.membershipPlanID].name;
  }
}
