import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/network/serverLine";
import universalLocalStorage from "../lib/universalLocalStorage";

export default function sendGoogleAccessToken(accessToken) {
  serverLine
    .post("/app-cookie", { accessToken })
    .then(done)
    .catch(({ message }) => {
      console.warn(message);
      if (typeof message == "object") message = JSON.stringify(message);
      window.popupAlert(message);
    });
}

async function done(data) {
  if (data) {
    console.log("received data", data);
    await universalLocalStorage.setItem("wellness-token", data.token);
    await universalLocalStorage.setItem("wellness-userID", data.userID);
    // window.location = "/";
    goTo("/")();
    window.doInitialLoad();
  }
}
