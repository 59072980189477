import styled from "styled-components";

const StylishUnderline = styled.div`
  height: 2px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  width: 62%;
  opacity: 1;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accentColor),
    transparent
  );
`;

export default StylishUnderline;
