import { useContext } from "react";
import AdminPageBoilerplate from "../AdminPageBoilerplate";
import AdminSettingsCard from "./AdminSettingsCard";
import Context from "../../../../../Context";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../../helperComponents/TitleBar";

export default function AdminSettingsPage() {
  const { adminSettings, updateAdminSettings } = useContext(Context);
  const { t } = useTranslation();

  let item = adminSettings ? adminSettings : {};

  let items = [item];

  return (
    <AdminPageBoilerplate title={t("adminPageSettingsTitle")}>
      <AdminSettingsCard
        item={item}
        index={0}
        items={items}
        setItems={setItems}
      />
    </AdminPageBoilerplate>
  );

  function setItems(newItems) {
    updateAdminSettings(newItems[0]);
  }
}
