import styled from "styled-components";
import WithBackground from "../../core/boilerplate/WithBackground";
import { useTranslation } from "react-i18next";
import LoggedOutHeader from "./landingPage/LoggedOutHeader";
import MembershipSelection from "../loggedIn/onboarding/MembershipSelection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Title = styled.h2``;

export default function LoggedOutMembershipPage() {
  const { t } = useTranslation();

  return (
    <WithBackground backgroundImage="/dumbbells.jpg">
      <Container>
        <LoggedOutHeader />

        <MembershipSelection />
      </Container>
    </WithBackground>
  );
}
