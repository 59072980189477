import MaterialInput from "../../../../helperComponents/MaterialInput";
import CustomButton from "../../../../helperComponents/CustomButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  width: 400px;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

export default function SearchUserByEmailBox({
  emailQuery,
  setEmailQuery,
  findUser,
}) {
  return (
    <Container>
      <MaterialInput
        label="Search User By Email"
        onEnter={findUser}
        value={emailQuery}
        onChange={(e) => {
          setEmailQuery(e.target.value);
        }}
      />
      <CustomButton onClick={findUser}>Find</CustomButton>
    </Container>
  );
}
