import styled from "styled-components";
import formatDate from "../../../../../controllers/formatDate";
import CustomButton from "../../../../helperComponents/CustomButton";
import submitData from "../../../../../controllers/request/submitData";
import { useState } from "react";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "./ExactSlotTime";

const Card = styled.div`
  background: #111111;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  color: white;
  position: relative;
  border: 1px solid #222;
  transition: all 0.25s ease-in-out;
`;

const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const JustText = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Details = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.div`
  font-weight: 900;
  /* justify-content: center; */
  /* text-align: center; */
  font-size: 20px;
  color: var(--accentColor);
  text-transform: uppercase;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: #808080;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  margin-top: 0;
  font-weight: 700;
`;

export default function ExistingCheckupBooking({ value, resetAndDoQuery }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (loading) return <LoadingSection />;

  return (
    <Card>
      <InfoContainer>
        <JustText>
          <Title>{formatDate(value.date)}</Title>

          <Details>
            <Subtitle>
              {t("scheduleCheckupTime")}:{" "}
              <ExactSlotTime slot={value.slot} hour={value.hour} />
            </Subtitle>
            <Subtitle>
              {t("scheduleCheckupStatus")}: {value.status.toLowerCase()}
            </Subtitle>
          </Details>
        </JustText>

        {showCancelButton()}
      </InfoContainer>
    </Card>
  );

  function showCancelButton() {
    if (value.status == "PENDING")
      return (
        <CustomButton onClick={cancelBooking}>
          {t("scheduleCheckupCancelBooking")}
        </CustomButton>
      );

    return null;
  }

  function cancelBooking() {
    submitData({
      method: "post",
      path: "/cancel-health-checkup-booking",
      data: { id: value._id },
      onSuccess: () => {
        resetAndDoQuery();
      },
      setLoading: setLoading,
    });
  }
}
