import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";
import limitStringLength from "../../../../../controllers/limitStringLength";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border: 1px solid #222;
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function ManageEmployeesCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
  dashboardType,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    {
      type: "IMAGE",
      name: "image",
      label: t("adminPageEmployeeFormImage"),
      forPerson: true,
    },
    { type: "STRING", name: "name", label: t("adminPageEmployeeFormName") },
    {
      type: "STRING",
      name: "designation",
      label: t("adminPageEmployeeFormDesignation"),
    },
    {
      type: "LONG_STRING",
      name: "description",
      label: t("adminPageEmployeeFormDescription"),
    },
  ];

  if (dashboardType !== "EMPLOYEE_DASHBOARD")
    fields.push({
      type: "STRING",
      name: "email",
      label: "email",
    });

  if (forAddingNewItem) {
    if (dashboardType == "EMPLOYEE_DASHBOARD") return null;
    return (
      <NewCard>
        <CustomButton style={{ background: "#000" }} onClick={addItem}>
          {t("clickHereToAddNew")}
        </CustomButton>
      </NewCard>
    );
  }

  return (
    <Card>
      <ProfileContainer>
        <Image src={getImageURL(item.image, true)} alt="Profile Image" />

        <ProfileText>
          <Name>{item.name} </Name>
          <Status>
            {t("adminPageEmployeeDesignation")}: {item.designation}
          </Status>
        </ProfileText>
      </ProfileContainer>

      <Data>
        <FiledValue>
          {t("adminPageEmployeeDescription")}:{" "}
          {limitStringLength(item.description, 100)}{" "}
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton style={{ background: "#000" }} onClick={editItem}>
          {t("adminPageEmployeeEditItem")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/employee"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/employee"
        />
      ),
    });
  }
}
