import { useEffect, useState } from "react";
import { serverLine } from "../../../../../controllers/network/serverLine";
import DropDownInput from "../../../../helperComponents/DropDownInput";
import { useTranslation } from "react-i18next";
import fetchDataWithCaching from "../../../../../controllers/network/fetchDataWithCaching";

export default function DropDownEmployee({ value, onChange }) {
  const [items, setItems] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    fetchDataWithCaching({ path: "/employees" }).then(setItems);
  }, []);

  return (
    <DropDownInput
      style={{ background: "var(--translucent)" }}
      label={t("upcomingActivitiesFilterLabel")}
      placeholder={t("upcomingActivitiesFilterPlaceholder")}
      value={value}
      onChange={onChange}
      options={items.map((item) => getItem(item))}
    />
  );

  function getItem(item) {
    return {
      onClick: () => {
        onChange(item._id);
      },
      value: item._id,
      isSelected: value === item._id,
      label: `${item.name}`,
    };
  }
}
