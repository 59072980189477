import { useContext, useState } from "react";

import CustomDateInput from "../../../helperComponents/CustomDateInput";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import { serverLine } from "../../../../controllers/network/serverLine";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import { IonDatetime } from "@ionic/react";
import styled from "styled-components";

let thisDate = new Date();

const DesktopDateInput = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const MobileDateInput = styled.div`
  border-radius: 10px;
  overflow: hidden;
  filter: contrast(1.4); //To get rid of the grey part
  @media (min-width: 900px) {
    display: none;
  }
`;

export default function AskDobPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [dob, setDob] = useState(thisDate.toISOString());
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <OnboardingBoilerplate
      title={t("onboardingDobTitle")}
      desc={t("onboardingDobDesc")}
      onSubmit={onSubmit}
      loading={loading}
    >
      <DesktopDateInput>
        <CustomDateInput
          style={{ justifyContent: "center" }}
          label=""
          value={dob}
          onChange={setDob}
        />
      </DesktopDateInput>

      <MobileDateInput>
        <IonDatetime
          value={dob}
          ionChange={{
            emit: (val) => {
              setDob(val.value);
            },
          }}
          // color={"dark"}

          preferWheel={true}
          presentation="date"
        ></IonDatetime>
      </MobileDateInput>
    </OnboardingBoilerplate>
  );

  async function onSubmit() {
    if (!dob) return window.popupAlert(t("onboardingDobPleaseEnterYourDob"));

    let age = calculateAge(dob);

    if (age < 10) return window.popupAlert(t("onboardingDobAgeLimit"));

    setLoading(true);
    try {
      await serverLine.patch("/me", { changes: { dateOfBirth: dob } });
      setLoading(false);
      let newLoggedInUser = { ...loggedInUser, dateOfBirth: dob };
      window.popupAlert("Saved");
      setLoggedInUser(newLoggedInUser);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  function calculateAge(dateOfBirthStr) {
    let dateOfBirth = new Date(dateOfBirthStr);
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDifference = today.getMonth() - dateOfBirth.getMonth();

    // If the current month is before the birth month or
    // if it's the same month but the day is before the birth day,
    // then subtract one from the age.
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    }

    return age;
  }
}
