import { useEffect, useState } from "react";
import MaterialInput from "../../../../helperComponents/MaterialInput";
import CustomButton from "../../../../helperComponents/CustomButton";
import styled from "styled-components";
import { serverLine } from "../../../../../controllers/network/serverLine";
import CustomToggle from "../../../../helperComponents/CustomToggle";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--translucentHard);
  padding: 25px;
  /* background: var(--translucentHard); */
  border-radius: 20px;
  width: 62vw;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 30px;
`;

export default function CreatePaymentEntryBox({
  data,
  membershipIdVsPlan,
  membershipPlans,
  findUser,
  setLoading,
}) {
  const [selectedMembershipID, setSelectedMembershipID] = useState(null);
  const [validity, setValidity] = useState(null);
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");

  useEffect(() => {
    if ((selectedMembershipID, validity)) {
      setAmount(calculateAmount(selectedMembershipID, validity));
    }
  }, [selectedMembershipID, validity]);

  if (!data) return null;
  if (!membershipIdVsPlan) return null;

  let membershipPlanOptions = [];

  for (let item of membershipPlans) {
    if (item.status !== "DISABLED")
      membershipPlanOptions.push({ label: item.name, value: item._id });
  }

  return (
    <Container>
      <Title>Add payment entry</Title>

      <CustomToggle
        name="Membership Plan"
        value={selectedMembershipID}
        options={membershipPlanOptions}
        onChange={setSelectedMembershipID}
      />

      <CustomToggle
        name="Validity"
        value={validity}
        options={getFutureMonthsArray()}
        onChange={setValidity}
      />

      {/* <MaterialInput
        label="Validity"
        type="date"
        value={validity}
        onChange={(e) => {
          setValidity(e.target.value);
        }}
      /> */}

      <MaterialInput
        label="Amount"
        type="number"
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value);
        }}
      />

      <MaterialInput
        label="Note"
        rows={3}
        multiline={true}
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
      />

      <CustomButton onClick={createIt}>Submit</CustomButton>
    </Container>
  );

  function getFutureMonthsArray() {
    const now = new Date();

    // Helper function to calculate future dates
    function addMonths(date, months) {
      const result = new Date(date);
      result.setMonth(result.getMonth() + months);
      result.setHours(0, 0, 0, 0); // Reset time to 00:00:00.000
      return result.toISOString();
    }

    // Generate the array
    const futureMonthsArray = [
      { label: "One Month", value: addMonths(now, 1) },
      { label: "Two Months", value: addMonths(now, 2) },
      { label: "Three Months", value: addMonths(now, 3) },
      { label: "6 Months", value: addMonths(now, 6) },
      { label: "1 Year", value: addMonths(now, 12) },
    ];

    return futureMonthsArray;
  }

  function calculateAmount(newPlanID, newValidity) {
    let factors = [1, 2, 3, 6, 12];
    let planValue = 0;
    let total = 0;
    if (membershipIdVsPlan[newPlanID]) {
      planValue = membershipIdVsPlan[newPlanID].price;
    }

    let validities = getFutureMonthsArray();

    let i = 0;
    for (let item of validities) {
      //   console.log(item, i, item.value, newValidity);
      if (item.value == newValidity) {
        total = planValue * factors[i];
      }
      i++;
    }

    return total;
  }

  async function createIt() {
    setLoading(true);
    try {
      await serverLine.post(`/admin/payment-entry/`, {
        userID: data.user._id,
        validity,
        membershipPlanID: selectedMembershipID,
        amount,
        note,
      });

      window.popupAlert("Payment entry added");

      findUser();
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }
}
