import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import calculateAge from "../../../../../controllers/calculateAge";
import parseDate from "../../../../../controllers/parseDate";
import parseExpiresAt from "../../../../../controllers/parseExpiresAt";

const Section = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--translucent);
  align-items: center;
  gap: 40px;
  width: 62vw;
  border-radius: 10px;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    padding: 30px;
    align-items: flex-start;
  }
`;

const ImageSection = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.div`
  font-weight: 900;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title2 = styled.div``;

export default function UserAndMembershipBox({ data, membershipIdVsPlan }) {
  if (!data) return null;
  return (
    <Section>
      <ImageSection
        src={getImageURL(data.user.profileImage, true)}
      ></ImageSection>

      <Text>
        <Title>
          {data.user.firstName} {data.user.lastName}
        </Title>

        <SubHeading>
          <Title2>{data.user.phoneNumber}</Title2>
          <Title2>Membership: {getPlanName(data.user.membershipPlanID)}</Title2>

          <Title2>
            Validity: {parseDate(data.user.membershipPlanValidity)}
          </Title2>

          <Title2>
            Expires In: {parseExpiresAt(data.user.membershipPlanValidity)}
          </Title2>

          <Title2>Age: {calculateAge(data.user.dateOfBirth)}</Title2>
        </SubHeading>
      </Text>
    </Section>
  );

  function getPlanName(membershipPlanID) {
    if (!membershipIdVsPlan[membershipPlanID]) {
      return "NA";
    }

    return membershipIdVsPlan[membershipPlanID].name;
  }
}
