import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "../../../../helperComponents/PrimaryButton";
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai";
import { FaPersonMilitaryPointing } from "react-icons/fa6";
import { CgLock } from "react-icons/cg";
import { GoClockFill } from "react-icons/go";
import getImageURL from "../../../../../controllers/getImageURL";
import { serverLine } from "../../../../../controllers/network/serverLine";
import LoadingSection from "../../../../helperComponents/LoadingSection";
import { useTranslation } from "react-i18next";
import goTo from "../../../../../controllers/goTo";
import SemanticButton from "../../../../helperComponents/SemanticButton";
import Context from "../../../../../Context";
import ActivityDetailsMobile from "./ActivityDetailsMobile";
import { IoTimeOutline } from "react-icons/io5";
import { RiUser2Line } from "react-icons/ri";
import { GrUserManager } from "react-icons/gr";
import { HiOutlineUser } from "react-icons/hi2";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  background-color: #000;
  color: #fff;
  width: 90vw;
  font-family: Arial, sans-serif;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 90px;
    padding: 0;
    width: 90vw;
  }
`;

const InfoSection = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TextContent = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-align: left;
  font-style: italic;
  margin: 0;
`;

const CoachAndDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    gap: 80px;
    width: 100%;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  font-size: 30px;
  opacity: 0.7;
`;

const Subtitle = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  color: var(--accentColor);
  margin: 0;
  padding: 0;
  font-style: italic;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  /* margin-bottom: 10px; */
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  opacity: 0.7;

  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  &::before {
    content: "•";
    color: #c9d400;
    margin-right: 8px;
  }

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 400px;
  border-radius: 0;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SeatsLeft = styled.div`
  font-size: 18px;
  padding: 20px;
  color: #c9d400;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--translucentLight);
  border: 1px solid #222;
  border-radius: 10px;
  overflow: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const EmployeeData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EmployeeName = styled.div`
  font-weight: 800;

  font-style: italic;
  font-weight: 800;

  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
`;

const EmployeeDesc = styled.div`
  opacity: 0.7;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    text-align: justify;
    text-justify: inter-word;
    font-size: 15px;
    line-height: 1.3;
  }
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const IconMainText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  font-weight: 600;
  display: flex;
  opacity: 0.7;

  font-family: "Montserrat", sans-serif;

  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const IconSecondaryText = styled.div`
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-style: italic; */
  text-transform: capitalize;
  font-weight: 800;
  display: flex;

  font-family: "Montserrat", sans-serif;

  color: var(--accentColor);
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;

function ActivityDetails({ item, bookingData }) {
  const { membershipPlans, updateMembershipPlans, isMobile } =
    useContext(Context);
  const [isBooked, setIsBooked] = useState(bookingData);
  const [state, setState] = useState(item);
  const [bookingInProgress, setBookingInProgress] = useState(false);

  useEffect(() => {
    updateMembershipPlans();
  }, []);

  const { t } = useTranslation();

  let bookButton = (
    <PrimaryButton
      onClick={doBooking}
      style={{ width: "100%", borderRadius: "10px" }}
    >
      Réserver
    </PrimaryButton>
  );

  let cancelBookingButton = (
    <PrimaryButton
      onClick={doBooking}
      textStyle={{ color: "#999" }}
      icon={null}
      style={{
        width: "100%",
        borderRadius: "10px",
        background: "#000",
        border: "1px solid #333",
        boxShadow: "none",
      }}
    >
      {t("activityItemCancelBooking")}
    </PrimaryButton>
  );

  if (!membershipPlans) return <LoadingSection />;
  let membershipIdVsPlan = {};

  for (let item of membershipPlans) {
    membershipIdVsPlan[item._id] = item;
  }

  let theButton = isBooked ? cancelBookingButton : bookButton;

  if (bookingInProgress) theButton = <LoadingSection />;

  let showReportButton = null;

  if (bookingData) {
    if (bookingData.reportFile) {
      theButton = null;

      showReportButton = (
        <PrimaryButton
          onClick={goTo(getImageURL(bookingData.reportFile))}
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        >
          {t("activityItemShowReport")}
        </PrimaryButton>
      );
    }
  }

  let textContent = (
    <>
      <CoachAndDuration>
        <SemanticButton
          onClick={goTo("/employee/?itemID=" + item.employee._id)}
        >
          <IconAndText>
            <Icon>
              <HiOutlineUser />
            </Icon>
            <IconText>
              <IconMainText> {t("activityItemCoach")} </IconMainText>
              <IconSecondaryText>{item.employee.name} </IconSecondaryText>
            </IconText>
          </IconAndText>
        </SemanticButton>

        <IconText>
          <IconAndText>
            <Icon>
              <IoTimeOutline />
            </Icon>

            <IconText>
              <IconMainText>{t("activityItemDuration")}</IconMainText>
              <IconSecondaryText>{item.duration} min</IconSecondaryText>
            </IconText>
          </IconAndText>
        </IconText>
      </CoachAndDuration>

      <Text>{item.activityType.description}</Text>

      <Section>
        <Subtitle>{t("activityItemDetails")} </Subtitle>
        <List>
          <ListItem>
            {t("activityItemParticipationType")}:{" "}
            {item.participationType ? t(item.participationType) : "N/A"}
          </ListItem>
          <ListItem>
            {t("activityItemMembershipType")}:{" "}
            {item.membershipPlanIDs
              ? item.membershipPlanIDs
                  .map((itemID) => {
                    let item = membershipIdVsPlan[itemID];
                    return item.name;
                  })
                  .join(", ")
              : "N/A"}
          </ListItem>
        </List>
      </Section>

      {bookingData ? (
        bookingData.remark ? (
          <Section>
            <Subtitle>{t("activityItemRemark")}</Subtitle>
            <List>
              <ListItem>{bookingData.remark}</ListItem>
              {bookingData.remark2 ? (
                <ListItem>{bookingData.remark2}</ListItem>
              ) : null}
              {bookingData.remark3 ? (
                <ListItem>{bookingData.remark3}</ListItem>
              ) : null}
            </List>
          </Section>
        ) : null
      ) : null}

      <Section>
        <Subtitle>{t("activityItemAboutCoach")}</Subtitle>
        <EmployeeData>
          <EmployeeName>{item.employee.name}</EmployeeName>
          <EmployeeDesc>{item.employee.description}</EmployeeDesc>
        </EmployeeData>
      </Section>
    </>
  );

  if (isMobile)
    return (
      <ActivityDetailsMobile
        item={item}
        state={state}
        theButton={theButton}
        textContent={textContent}
        showReportButton={showReportButton}
      />
    );

  return (
    <Container>
      <InfoSection>
        <Title>{item.activityType.title}</Title>
        <TextContent> {textContent}</TextContent>
      </InfoSection>

      <RightSection>
        <ImageSection>
          <Image src={getImageURL(item.activityType.image)} />
          <SeatsLeft>{state.seats - state.seatsBooked} Seats Left</SeatsLeft>
        </ImageSection>

        {showReportButton}
        {theButton}
      </RightSection>
    </Container>
  );

  async function doBooking() {
    try {
      setBookingInProgress(true);

      await serverLine.post("/activity-booking", {
        activityID: item._id,
        action: isBooked ? "UNBOOK" : "BOOK",
      });

      let newState = { ...state };

      if (isBooked) {
        newState.seatsBooked--;
      } else {
        newState.seatsBooked++;
      }

      setState(newState);

      window.popupAlert("Done");

      setIsBooked(isBooked ? false : true);
      setBookingInProgress(false);
    } catch (e) {
      window.popupAlert(e.message);
      setBookingInProgress(false);
    }
  }
}

export default ActivityDetails;
