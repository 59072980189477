import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import { serverLine } from "../../../../../controllers/network/serverLine";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import formatDate from "../../../../../controllers/formatDate";
import { useTranslation } from "react-i18next";
import ExactSlotTime from "../../health/scheduleCheckup/ExactSlotTime";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  width: 90%;
  /* text-transform: uppercase; */
  font-weight: 900;
`;

const Status = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 700;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function ManageHealthCheckupCard({
  item,
  index,
  items,
  setItems,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  return (
    <Card>
      <ProfileContainer>
        <Image
          src={getImageURL(item.user.profileImage, true)}
          alt="Profile Image"
        />

        <ProfileText>
          <Name>
            {item.user.firstName} {item.user.lastName}
          </Name>
          <Status style={getStatusStyle(item.status)}>
            Status: {item.status.toLowerCase()}
          </Status>
        </ProfileText>
      </ProfileContainer>

      <Data>
        <FiledValue>Date: {formatDate(item.date)}</FiledValue>
        <FiledValue>
          Time: <ExactSlotTime slot={item.slot} hour={item.hour} />
        </FiledValue>
      </Data>

      <ActionButtons>
        {item.status !== "DONE" ? (
          <CustomButton style={{ background: "#000" }} onClick={markAsDone}>
            Mark As Done
          </CustomButton>
        ) : null}
        {item.status == "PENDING" ? (
          <CustomButton style={{ background: "#000" }} onClick={decline}>
            Decline
          </CustomButton>
        ) : null}
        <CustomButton
          style={{ background: "#000" }}
          onClick={promptReportUpload}
        >
          Upload Report
        </CustomButton>
        {/* <CustomButton onClick={changeDateOrTime}>
          Change Date Or Time
        </CustomButton> */}
      </ActionButtons>
    </Card>
  );

  function changeDateOrTime() {
    let fields = [
      {
        type: "UPCOMING_DATES",
        name: "date",
        label: t("adminHealthCheckupsSelectDate"),
      },
      {
        type: "HOUR_RANGE",
        name: "hour",
        label: t("adminHealthCheckupsSelectHour"),
      },
      {
        type: "HEALTH_CHECKUP_SLOT",
        name: "slot",
        label: t("adminHealthCheckupsSelectSlot"),
      },
    ];

    setForm({
      title: "Change Date And Time",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/health-checkup"
        />
      ),
    });
  }

  function promptReportUpload() {
    let fields = [
      { type: "FILE", name: "reportFile", label: "PDF" },
      {
        type: "NUMBER",
        name: "reportData.weight",
        label: t("adminHealthCheckupsWeight"),
        // placeholder: "Type weight here",
      },
      {
        type: "NUMBER",
        name: "reportData.leanBodyMass",
        label: t("adminHealthCheckupsLeanBodyMass"),
        // placeholder: "Type lean body mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.muscleMass",
        label: t("adminHealthCheckupsMuscleMass"),
        // placeholder: "Type muscle mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.bodyWater",
        label: t("adminHealthCheckupsBodyWater"),
        // placeholder: "Type body water here",
      },
      {
        type: "NUMBER",
        name: "reportData.bodyFatMass",
        label: t("adminHealthCheckupsBodyFatMass"),
        // placeholder: "Type body fat mass here",
      },
      {
        type: "NUMBER",
        name: "reportData.inorganicSalts",
        label: t("adminHealthCheckupsInorganicSalt"),
        // placeholder: "Type inorganic salt here",
      },
      {
        type: "NUMBER",
        name: "reportData.protein",
        label: t("adminHealthCheckupsProtein"),
        // placeholder: "Type protein here",
      },
      {
        type: "LONG_STRING",
        name: "remark",
        label: t("adminHealthCheckupsRemark"),
        // placeholder: "Type remark here",
      },
    ];

    setForm({
      title: "Upload Report",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/health-checkup"
        />
      ),
    });
  }

  function getStatusStyle(status) {
    if (status == "PENDING") return { background: "yellow", color: "#111" };
    if (status == "DECLINED") return { background: "tomato", color: "#111" };
    if (status == "DONE") return { background: "steelblue", color: "#111" };
  }

  function updateField(field, newValue) {
    let newItem = { ...item };
    newItem[field] = newValue;

    let newItems = [...items];
    newItems[index] = newItem;

    setItems(newItems);

    window.popupAlert("Done");

    serverLine.patch("/admin/health-checkup", {
      id: item._id,
      changes: { [field]: newValue },
    });
  }

  function markAsDone() {
    updateField("status", "DONE");
  }

  function decline() {
    updateField("status", "DECLINED");
  }
}
