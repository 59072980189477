import styled from "styled-components";
import WithBackground from "../../core/boilerplate/WithBackground";
import LoggedOutHeader from "./landingPage/LoggedOutHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Title = styled.h2``;

export default function LoggedOutBoilerplate({ children }) {
  return (
    <Container>
      <LoggedOutHeader />

      {children}
    </Container>
  );
}
