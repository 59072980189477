import styled from "styled-components";

import CustomButton from "../../../helperComponents/CustomButton";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import InputCard from "../../../helperComponents/InputCard";
import logout from "../../../../controllers/logout";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useContext, useEffect, useState } from "react";
import Context from "../../../../Context";
import adminAccess from "../../../../data/adminAccess";
import { serverLine } from "../../../../controllers/network/serverLine";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import CartItem from "./CartItem";
import MessageBox from "../../../helperComponents/MessageBox";
import LoadingSection from "../../../helperComponents/LoadingSection";
import goTo from "../../../../controllers/goTo";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../helperComponents/TitleBar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;
`;
const MainButton = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CartPage() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [address, setAddress] = useState(null);
  const [storeListingData, setStoreListingData] = useState(null);
  const [loading, setLoading] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setAddress(loggedInUser.address);
    serverLine.get("/cart").then(setStoreListingData);
  }, []);

  let titleBar = <TitleBar> {t("cartPageTitle")} </TitleBar>;

  if (!storeListingData || loading)
    return (
      <LoggedInBoilerplate showBackgroundImage={true}>
        {titleBar}
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  let storeListingMap = {};

  for (let storeListing of storeListingData) {
    storeListingMap[storeListing._id] = storeListing;
  }

  let { itemList, totalPrice } = calculateStuff();

  if (!itemList.length)
    return (
      <LoggedInBoilerplate showBackgroundImage={true}>
        {titleBar}
        <MessageBox>{t("cartIsEmpty")}</MessageBox>
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      {titleBar}

      <Container>
        <InputCard style={{ width: "700px", padding: "30px 70px" }}>
          <Inputs>
            {itemList}

            <MaterialInput
              label={t("cartPageAddress")}
              rows={2}
              multiline={true}
              value={address}
              onChange={extractEventValue(setAddress)}
            />

            <MainButton>
              <PrimaryButton
                style={{ borderRadius: "10px", width: "400px" }}
                onClick={placeOrder}
              >
                {t("pay")} {totalPrice}
              </PrimaryButton>
            </MainButton>
          </Inputs>
        </InputCard>
      </Container>
    </LoggedInBoilerplate>
  );

  function checkIfAnythingIsOutOfStock() {
    let items = loggedInUser.cart;
    if (!items) return false;

    for (let itemID in items) {
      let listing = storeListingMap[itemID];
      let quantity = items[itemID].quantity;
      let stock = listing.currentStock;

      if (quantity > stock) {
        window.popupAlert(
          `Decrease the quantity of ${listing.title} as that many quantity is not available`
        );
        return true;
      }
    }

    return false;
  }

  function calculateStuff() {
    let items = loggedInUser.cart;
    if (!items) return { itemList: [], totalPrice: 0 };

    let itemList = [];

    let totalPrice = 0;

    for (let itemID in items) {
      let quantity = items[itemID].quantity;
      let listingData = storeListingMap[itemID];
      let price = listingData.price;

      totalPrice += price * quantity;
      itemList.push(
        <CartItem listingData={listingData} price={price} quantity={quantity} />
      );
    }

    return { itemList, totalPrice };
  }

  async function placeOrder() {
    if (!address) return window.popupAlert(" Address is required ");

    let allNotClear = checkIfAnythingIsOutOfStock();

    if (allNotClear) return;
    setLoading(true);

    await serverLine.post("/place-order", { address });

    let newLoggedInUser = { ...loggedInUser, cart: null };
    setLoggedInUser(newLoggedInUser);

    goTo("/my-orders")();

    setLoading(false);
  }
}
