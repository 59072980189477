import { useContext, useState } from "react";
import WithBackground from "../../core/boilerplate/WithBackground";
import CustomToggle from "../../helperComponents/CustomToggle";
import PrimaryButton from "../../helperComponents/PrimaryButton";
import LoggedOutHeader from "../loggedOut/landingPage/LoggedOutHeader";
import styled from "styled-components";
import MaterialInput from "../../helperComponents/MaterialInput";
import extractEventValue from "../../../controllers/utils/extractEventValue";
import InputCard from "../../helperComponents/InputCard";
import CustomButton from "../../helperComponents/CustomButton";
import getUrlQuery from "../../../controllers/getUrlQuery";
import MessageBox from "../../helperComponents/MessageBox";
import LoadingSection from "../../helperComponents/LoadingSection";
import { serverLine } from "../../../controllers/network/serverLine";
import saveUserAuth from "../../../controllers/auth/saveUserAuth";
import { useTranslation } from "react-i18next";
import Footer from "../loggedOut/landingPage/Footer";
import Context from "../../../Context";

const options = [
  { value: "LOGIN", label: "Login" },
  { value: "SIGNUP", label: "Signup" },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;

  width: 100vw;

  align-items: center;

  @media (max-width: 900px) {
    gap: 30px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-end;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 30px;
  color: var(--accentColor);

  width: 90%;
  text-transform: uppercase;
  font-weight: 900;

  font-family: "Montserrat", sans-serif;
`;

const Content = styled.div`
  width: 62vw;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Text = styled.div``;

export default function FooterPageBoilerplate({ title, children }) {
  const { loggedInUserID } = useContext(Context);
  return (
    <WithBackground>
      <Container>
        {loggedInUserID ? null : <LoggedOutHeader />}

        <Content>
          <Title>{title}</Title>

          <Text dangerouslySetInnerHTML={{ __html: children }}></Text>

          <br />
          <br />
          <br />
          <br />
        </Content>
        <Footer />
      </Container>
    </WithBackground>
  );
}
