import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useTranslation } from "react-i18next";
import TitleBar from "../../../helperComponents/TitleBar";
import LoggedInMembershipPlans from "./LoggedInMembershipPlans";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 62vw;
  align-items: center;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h2``;

export default function LoggedInMembershipPage() {
  const { t } = useTranslation();

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <TitleBar>Membership Plans</TitleBar>
      <Container>
        <LoggedInMembershipPlans />
      </Container>
    </LoggedInBoilerplate>
  );
}
