import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import { serverLine } from "../../../../controllers/network/serverLine";
import MembershipPlanRequested from "./MembershipPlanRequested";
import { useContext } from "react";
import Context from "../../../../Context";
import AskMembershipPlanConfirmation from "./AskMembershipPlanConfirmation";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 30px;
  /* padding-right: 50px; */

  color: #ffffff;

  gap: 30px;
  font-weight: bold;
  font-size: 20px;

  padding: 20px 30px;
  border-radius: 20px;
  background: var(--translucent);
  border: 1px solid #222;

  padding-bottom: 35px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const CardTitle = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const Features = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0.8;
  flex-direction: column;
`;

const PlanName = styled.span`
  color: var(--accentColor);
  font-size: 19px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  text-transform: uppercase;
  font-style: italic;
`;

const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
`;

const PriceTag = styled.div`
  color: var(--accentColor);
  font-size: 13px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #c9d400;
  /* margin-bottom: 10px; */
  /* border-bottom: 2px solid; */
`;

const LoggedInMembershipPlanCard = ({
  onChange,
  price,
  title,
  desc,
  name,
  features,
  setLoading,
  membershipPlanID,
  loadStuff,
}) => {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();
  return (
    <Card
      onClick={() => {
        if (onChange) onChange(name);
      }}
    >
      <CardTitle>
        <PlanName>{title}</PlanName>
        <PriceTag>{price}</PriceTag>
      </CardTitle>
      <PlanDescription>{desc}</PlanDescription>
      {/* <PriceTag>{price}</PriceTag> */}
      <Features>{features}</Features>

      <PrimaryButton
        onClick={showPopup}
        style={{ width: "100%", borderRadius: "10px" }}
      >
        {t("requestMembership")}
      </PrimaryButton>
    </Card>
  );

  function showPopup() {
    setForm({
      // title: "Request plan",
      component: (
        <AskMembershipPlanConfirmation
          title={title}
          sendRequest={sendRequest}
          price={price}
        />
      ),
    });
  }

  async function sendRequest(settings = {}) {
    let { secondarySetLoading, onSuccess } = settings;
    // setLoading(true);
    if (secondarySetLoading) secondarySetLoading(true);

    try {
      await serverLine.post("/membership-request", {
        membershipPlanID: membershipPlanID,
      });

      if (onSuccess) onSuccess(true);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
      if (secondarySetLoading) secondarySetLoading(false);
    }

    await loadStuff();
    // setLoading(false);
    if (secondarySetLoading) secondarySetLoading(false);
  }
};

export default LoggedInMembershipPlanCard;
