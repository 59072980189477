import styled from "styled-components";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import { RiAdminLine } from "react-icons/ri";
import CustomToggle from "../../../helperComponents/CustomToggle";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Context from "../../../../Context";
import LoginWall from "../LoginWall";
import TitleBar from "../../../helperComponents/TitleBar";
import MaterialTabs from "../../../helperComponents/MaterialTabs";
import DropDownInput from "../../../helperComponents/DropDownInput";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  gap: 50px;

  align-items: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 30px;
    /* align-items: center; */
  }
`;

const Top = styled.div`
  padding: 10px 0;
  border-radius: 10px;
  border: 1px solid #222;
  background-color: var(--translucentLight);

  @media (max-width: 900px) {
    border: none;
    background: transparent;
  }
`;

const TitleSection = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  width: 100%;
  /* width: 62vw; */
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;

  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  font-size: 30px;
  color: var(--accentColor);
  font-size: 18px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #fff;

  @media (max-width: 900px) {
    font-size: 17px;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  width: 100%;
`;

let toggleGridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "90vw",
};

let toggleItemStyle = { fontSize: "14px" };

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media (max-width: 900px) {
    gap: 20px;
    margin-top: 10px;
  }
`;
export default function AdminPageBoilerplate({ title, children }) {
  const { loggedInUserID, isMobile } = useContext(Context);
  const { t } = useTranslation();

  if (!loggedInUserID) return <LoginWall />;

  let options = [
    {
      value: "/admin/manage-health-checkups",
      label: t("adminPageHealthCheckupTitle"),
    },
    {
      value: "/admin/manage-users",
      label: t("adminPageManageUsersTitle"),
    },
    {
      value: "/admin/payment-entry",
      label: t("adminPagePaymentEntryTitle"),
    },
    {
      value: "/admin/membership-requests",
      label: t("adminPageMembershipReqTitle"),
    },
    {
      value: "/admin/manage-membership-plans",
      label: t("adminPageMembershipPlansTitle"),
    },
    {
      value: "/admin/manage-upcoming-health-checkups",
      label: t("adminPageUpcomingHealthCheckupTitle"),
    },
    { value: "/admin/manage-employees", label: t("adminPageEmployeesTitle") },
    {
      value: "/admin/manage-activity-types",
      label: t("adminPageActivityTypesTitle"),
    },
    { value: "/admin/manage-activities", label: t("adminPageActivitiesTitle") },
    { value: "/admin/manage-store", label: t("adminPageStoreTitle") },
    {
      value: "/admin/manage-announcements",
      label: t("adminPageAnnouncementsTitle"),
    },
    { value: "/admin/manage-orders", label: t("adminPageOrdersTitle") },
    { value: "/admin/manage-settings", label: t("adminPageSettingsTitle") },
  ];

  return (
    <LoggedInBoilerplate showBackgroundImage={false}>
      <TitleBar onlyMobile={true}> Admin Page </TitleBar>
      <Container>
        <Top>{getTabs()}</Top>

        <Content>
          <TitleSection>
            <Icon>
              <RiAdminLine />
            </Icon>

            <Title>
              {t("adminPageName")} / {title}
            </Title>
          </TitleSection>

          <Main>{children}</Main>
        </Content>
      </Container>
    </LoggedInBoilerplate>
  );

  function getTabs() {
    if (isMobile)
      return (
        <DropDownInput
          label="Admin Navigation"
          isLinkBased={true}
          options={options}
        />
      );
    return (
      <MaterialTabs
        isLinkBased={true}
        orientation={isMobile ? null : "vertical"}
        tabStyle={
          isMobile ? null : { textAlign: "left", alignItems: "flex-start" }
        }
        options={options}
      />
    );
  }
}

/*


   toggleStyle={
              isMobile
                ? toggleGridStyle
                : {
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                  }
            }
            btnStyle={isMobile ? toggleItemStyle : {}}

               isLinkBased={true}
               
*/
